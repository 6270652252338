import React, {useEffect, useState} from "react"
import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow} from "@mui/material";
import {FEEDBACK} from "../util/util";
import axios from "axios";
import ScrollToTop from "react-scroll-to-top";

interface Message {
    id: number,
    email: string,
    contact: string,
    text: string,
    created_at: string,
    updated_at: string,
}

const cells: (keyof Message)[] = ['id', 'email', 'contact', 'text', 'created_at'];

/**
 * Список запросов с формы фидбека
 */
const Feedback = () => {
    const [messages, setMessages] = useState<Message[]>([])
    const [page, setPage] = useState<number>(0)

    useEffect(() => {
        const key = (new URLSearchParams(window.location.search)).get('key')
        axios.get(FEEDBACK + "?key=" + key).then(res => setMessages(res.data))
    }, [])

    const getPageItems = () => messages.slice(page * 25, page * 25 + 25)

    return [
        <TableContainer key='table' sx={{margin: '30px auto', maxWidth: '1400px'}} component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        {cells.map(it => <TableCell key={it}>{it}</TableCell>)}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {getPageItems().map(msg => <TableRow key={msg.id}>
                        {cells.map(it => <TableCell key={it}>{msg[it]}</TableCell>)}
                    </TableRow>)}
                </TableBody>
            </Table>
            <TablePagination rowsPerPageOptions={[25]} component='div' count={messages.length} page={page}
                             rowsPerPage={25}
                             onPageChange={(_, page) => setPage(page)}/>
        </TableContainer>,
        <ScrollToTop id='scroll-to-top' key='scroll' smooth={true}/>
    ]
}

export default Feedback