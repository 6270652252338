import {TileLayer, useMapEvent} from "react-leaflet";
import React, {useState} from "react";

const mapLayer = "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
const terrainLayer = "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"

const TileLayerSwitch = () => {
    const [layer, setLayer] = useState(mapLayer)

    // @ts-ignore
    useMapEvent('zoom', e => setLayer(e.target.getZoom() > 15 ? terrainLayer : mapLayer))

    // @ts-ignore
    return <TileLayer attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                      url={layer}
    />
}
export default TileLayerSwitch
