import React from "react";
import { useTranslation } from "react-i18next";
import {
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    Link,
    List,
    ListItem,
    Typography
} from "@mui/material";
import { Close } from "@mui/icons-material";

interface PublicOfferProps {
    open: boolean,
    close: () => void
}

const PublicOfferModal = ({open, close}: PublicOfferProps) => {
    const {t} = useTranslation();

    return <Dialog
        fullWidth
        open={open}
        onClose={close}
    >
        <DialogTitle>
            {t('public_offer_title')}
            <IconButton
                onClick={close}
                size="small"
                sx={{
                    position: 'absolute',
                    right: '10px',
                    top: '10px'
                }}
            >
                <Close/>
            </IconButton>
        </DialogTitle>
        <DialogContent>
            <Typography variant="body1" gutterBottom>
                Політику опубліковано: 18.08.2023
            </Typography>
            <Typography variant="body1" gutterBottom>
                Поточна редакція: 1
            </Typography>
            <Typography variant="body1" align="justify" gutterBottom>
                Ця публічна оферта відповідно до ст. 641 Цивільного кодексу України, є пропозицією фізичної особи-підприємця Саласенко Сергія Виталійовича, реєстраційний номер облікової картки платника податків 3163312917, номер запису про проведення державної реєстрації: 10000000103315 від 08.05.2020, невизначеному колу дієздатних фізичних осіб, які використовують Платформу, укласти договір про надання послуг.
            </Typography>
            <Typography variant="body1" align="justify" gutterBottom>
            Терміни, що використовуються:
            </Typography>
            <Typography variant="body1" align="justify" gutterBottom>
                Компанія - ФОП Саласенко Сергій Виталійович, РНОКПП 3163312917, номер запису про проведення державної реєстрації: 10000000103315 від 08.05.2020, що здійснює адміністрування Сайту, та є стороною Договору.
            </Typography>
            <Typography variant="body1" align="justify" gutterBottom>
                Платформа – вебсайт за посиланням: <Link href="https://klaptuk.com/" target="_blank" rel="noreferrer">https://klaptuk.com/</Link>, та весь контент, що міститься у ньому.
            </Typography>
            <Typography variant="body1" align="justify" gutterBottom>
                Оферта - ця публічна оферта, що розташована на Платформі
            </Typography>
            <Typography variant="body1" align="justify" gutterBottom>
                Користувач - особа, яка відвідує та/або користується Платформою (надалі - «Ви», «Вас», «Вам»).
            </Typography>
            <Typography variant="body1" align="justify" gutterBottom>
                Володілець – дієздатна фізична особа, яка за допомогою функціоналу Платформи здійснює придбання Об'єкта. Для цілей цього Договору після придбання Об'єкта на території російської федерації поняття «Володілець» під час користування Платформою може входити до змісту поняття «Користувач».
            </Typography>
            <Typography variant="body1" align="justify" gutterBottom>
                Обʼєкт - віртуальна земельна ділянка в межах території російської федерації на онлайн-мапі, що не має жодного юридичного звʼязку з існуючим відповідним обʼєктом в реальному житті, та надаються для продажу Компанією виключно в розважальних цілях.
            </Typography>
            <Typography variant="body1" align="justify" gutterBottom>
                Володіння - сукупність Обʼєктів, які були придбанні Користувачем самостійно або спільно з іншими Користувачами Платформи. Фіксуються на  онлайн-мапі Платформи та сувенірній  цифровій продукції.
            </Typography>
            <Typography variant="body1" align="justify" gutterBottom>
                Сертифікат- сувенірна цифрова продукція, яку отримує Володілець після придбання Об’єкта.
            </Typography>
            <Typography variant="body1" align="justify" gutterBottom>
                Акцепт – повне і безумовне прийняття Оферти шляхом придбання Об'єкта за допомогою платіжних форм та засобів, розміщених на Платформі.
            </Typography>
            <Typography variant="h5" align="justify" gutterBottom>
                1. Загальні положення.
            </Typography>
            <Typography variant="body1" align="justify" gutterBottom>
                1.1. Згідно зі ст. ст. 633, 638, 641, 642 Цивільного кодексу України, цей документ є Публічною офертою, і в разі прийняття (Акцепту) викладених нижче умов, будь-яка дієздатна фізична особа, що користується Платформою, підтверджує, що вона цілком і повністю ознайомлена і згодна з умовами Оферти та зобов'язується виконувати та дотримуватись положень Оферти.
            </Typography>
            <Typography variant="body1" align="justify" gutterBottom>
                1.2. Користувач та Компанія погоджуються, що з моменту Акцепту договір про надання послуг (надалі - «Договір») вважається укладеним, а також з тим, що недодержання письмової форми правочину не означає його недійсність.
            </Typography>
            <Typography variant="body1" align="justify" gutterBottom>
                1.3. Ця Оферта є безстроковою та набирає чинності з моменту її розміщення на Платформі.
            </Typography>
            <Typography variant="body1" align="justify" gutterBottom>
                1.4. Умови Оферти є однаковими для всіх.
            </Typography>
            <Typography variant="body1" align="justify" gutterBottom>
                1.5. Якщо Ви не згодні з положеннями Оферти повністю або частково, або не маєте достатнього обсягу дієздатності для користування Платформою, або для придбання Об'єкта, будь ласка, припиніть користування Платформою.
            </Typography>
            <Typography variant="body1" align="justify" gutterBottom>
                1.6. У разі Акцепту Користувач беззастережно підтверджує свою дієздатність та добровільність укладання правочину.
            </Typography>
            <Typography variant="h5" align="justify" gutterBottom>
                2.Предмет Договору.
            </Typography>
            <Typography variant="body1" align="justify" gutterBottom>
                2.1. Компанія зобов'язується на умовах та в порядку, визначених цим Договором надати Користувачу інформаційні послуги через Платформу, зміст яких полягає в наданні доступу Користувачу в Особистому кабінеті Платформи до придбаних Обʼєктів та забезпеченні можливості розпоряджатись ними відповідно до умов, що викладені в Договорі, а Користувач зобов'язується на умовах та в порядку, визначених цим Договором, оплатити послуги та отримати їх в повному обсязі. Вартість конкретного Об'єкта визначається на Платформі.
            </Typography>
            <Typography variant="body1" align="justify" gutterBottom>
                2.2. Укладення цього Договору проводиться шляхом беззастережного приєднання Користувачем та/або Володільцем до цього Договору, тобто за допомогою прийняття (Акцепту) Користувачем та/або Володільцем умов цього Договору, шляхом придбання Об'єкта. Надалі заперечення від Користувача та/або Володільця щодо надання таким чином своєї згоди позбавляє Компанію від будь-якого роду відповідальності за невиконання умов цього Договору.
            </Typography>
            <Typography variant="body1" align="justify" gutterBottom>
                2.4. Місцем укладання даного Договору вважається місцезнаходження Компанії.
            </Typography>
            <Typography variant="body1" align="justify" gutterBottom>2.5. У зв’язку з вищевикладеним, уважно прочитайте текст даного Договору, і якщо Ви не згодні з яким-небудь пунктом Договору, Компанія пропонує Вам відмовитися від користування Платформою.
            </Typography>
            <Typography variant="h5" align="justify" gutterBottom>
                Порядок використання Платформи.
            </Typography>
            <Typography variant="body1" align="justify" gutterBottom>
                3.1. Порядок придбання Обʼєктів.
            </Typography>
            <Typography variant="body1" align="justify" gutterBottom>
                3.1.1. Користувач має можливість придбати Обʼєкти будь-яким із запропонованих способів, які можуть відрізнятись в залежності від типу Обʼєкта:
            </Typography>
            <Typography variant="body1" align="justify" gutterBottom>
                а) шляхом знаходження вільного Обʼєкта на онлайн-мапі в межах території російської федерації, у якого ще немає власника, та натиснувши «Придбати» при виборі такого віртуального Обʼєкта;
            </Typography>
            <Typography variant="body1" align="justify" gutterBottom>
                б) шляхом спільного придбання певного Обʼєкта разом з іншими Користувачами Платформи.
            </Typography>
            <Typography variant="body1" align="justify" gutterBottom>
                3.1.2. Розрахунки здійснюються за допомогою інтернет-еквайрингу від Приват банк. Компанія бере на себе обов'язок сплачувати комісію платіжної системи з еквайрингу при здійсненні кожної транзакції.
            </Typography>
            <Typography variant="body1" align="justify" gutterBottom>
                3.1.3. Після того, як Користувач визначиться із бажаним способом придбання Обʼєкта, які перелічені в п. 3.2.1., його буде перенаправлено на сторінку оплати. На сторінці Користувач може обрати будь-який із доступних способів переказу коштів, а саме:
            </Typography>
            <List>
                <ListItem>
                    <Typography variant="body1" align="justify">
                        - карткою шляхом введення її даних: номер картки, її строк дії та CVC2;
                    </Typography>
                </ListItem>
                <ListItem>
                    <Typography variant="body1" align="justify">
                        - через Apple Pay, Google Pay;
                    </Typography>
                </ListItem>
                <ListItem>
                    <Typography variant="body1" align="justify">
                        - за допомогою Приват24.
                    </Typography>
                </ListItem>
            </List>
            <Typography variant="body1" align="justify" gutterBottom>
                3.1.4. Повернення товару здійснюється протягом 14 днів з дня відправки згідно:
            </Typography>
            <Typography variant="body1" align="justify">
                ЗАКОН УКРАЇНИ
            </Typography>
            <Typography variant="body1" align="justify">
                Про захист прав споживачів
            </Typography>
            <Typography variant="body1" align="justify" gutterBottom>
                <Link href="https://zakon.rada.gov.ua/laws/show/1023-12#Text" target="_blank" rel="noreferrer">https://zakon.rada.gov.ua/laws/show/1023-12#Text</Link>
            </Typography>
            <Typography variant="body1" align="justify" gutterBottom>
                Користувач може звернутися до Компанії з питань повернення товару за допомогою наступної електронної адреси: klaptuk.peremogu@gmail.com
            </Typography>
            <Typography variant="body1" align="justify" gutterBottom>
                3.2. Використання Платформи.
            </Typography>
            <Typography variant="body1" align="justify" gutterBottom>
                3.2.1. Володілець розуміє та погоджується, що придбання Обʼєктів на онлайн-мапі не створює жодних юридичних прав у Володільця щодо відповідних обʼєктів у житті. Даний концепт було використано Компанією виключно в розважальних цілях.
            </Typography>
            <Typography variant="body1" align="justify" gutterBottom>
                3.2.2. У разі виникнення питань щодо функціональних можливостей Платформи та/або порядку придбання Обʼєктів, Користувач може звернутися до Компанії за допомогою наступної електронної адреси: klaptuk.peremogu@gmail.com.
            </Typography>
            <Typography variant="h5" align="justify" gutterBottom>
                4. Права та обов’язки Компанії.
            </Typography>
            <Typography variant="body1" align="justify">
                4.1. Компанія має право:
            </Typography>
            <List>
                <ListItem>
                    <Typography variant="body1" align="justify">
                        - при порушенні положень цих цього Договору Користувачем, обмежити або припинити доступ Користувача до Платформи в будь-який час, не повідомивши попередньо Користувача про це. Компанія не несе ніякої матеріальної чи моральної відповідальності перед Користувачами в разі такого обмеження або зупинення;
                    </Typography>
                </ListItem>
                <ListItem>
                    <Typography variant="body1" align="justify">
                        - в будь-який час в односторонньому порядку змінити Договір повністю та/або деякі його положення;
                    </Typography>
                </ListItem>
                <ListItem>
                    <Typography variant="body1" align="justify">
                        - удосконалювати Платформу та додавати нові функції;
                    </Typography>
                </ListItem>
                <ListItem>
                    <Typography variant="body1" align="justify">
                        - розміщувати фінансові звіти про передання відсотка від отриманого прибутку, у порядку та розмірі передбаченому п. 2.6 Договору, на Платформіта обліковому записі в Instagram (<Link href="https://www.instagram.com/klaptuk.peremogu" target="_blank" rel="noreferrer">https://www.instagram.com/klaptuk.peremogu</Link>).
                    </Typography>
                </ListItem>
            </List>
            <Typography variant="body1" align="justify">
                4.2. Компанія зобов'язується:
            </Typography>
            <List>
                <ListItem>
                    <Typography variant="body1" align="justify">
                        надавати послуги користування Платформою, а також  доступ до придбаних Володільцем Обʼєктів та можливість розпоряджатися нимивідповідно до умов, що викладені в Договорі.
                    </Typography>
                </ListItem>
            </List>
            <Typography variant="h5" align="justify" gutterBottom>
                5. Права та обовʼязки Володільця.
            </Typography>
            <Typography variant="body1" align="justify">
                5.1. Володілець має право:
            </Typography>
            <List>
                <ListItem>
                    <Typography variant="body1" align="justify">
                        отримати інформацію щодо порядку реєстрації на Платформі, придбання Обʼєктів та способів розпорядження ними;
                    </Typography>
                </ListItem>
                <ListItem>
                    <Typography variant="body1" align="justify">
                        отримати віртуальний Обʼєкт у Володіння після здійснення в повному обсязі оплати вартості відповідного Обʼєкту, або у спільне Володіння з іншими Користувачами Платформи у разі часткової оплати вартості Об'єкта;
                    </Typography>
                </ListItem>
                <ListItem>
                    <Typography variant="body1" align="justify">
                        вимагати від Компанії виконання умов та положень цього Договору;
                    </Typography>
                </ListItem>
                <ListItem>
                    <Typography variant="body1" align="justify">
                        повідомити Компанію про порушення, що пов'язані з цим Договором та/або законодавством України.
                    </Typography>
                </ListItem>
            </List>
            <Typography variant="body1" align="justify">
                5.2. Володілець зобов’язаний:
            </Typography>
            <List>
                <ListItem>
                    <Typography variant="body1" align="justify">
                        у повному обсязі дотримуватися умов даного Договору, а також не здійснювати дій, спрямованих на дестабілізацію роботи Платформи;
                    </Typography>
                </ListItem>
                <ListItem>
                    <Typography variant="body1" align="justify">
                        не порушувати положення Договору, а також дотримуватися законних прав та інтересів Компанії;
                    </Typography>
                </ListItem>
                <ListItem>
                    <Typography variant="body1" align="justify">
                        придбанням Об'єкта Володілець беззастережно підтверджує (і) свою дієздатність, (іі) добровільність здійснення Акцепту, (ііі) що грошові кошти, які були використані Володільцем для оплати вартості Об'єкта не знаходяться під забороною, арештом, не перебувають у заставі, не обтяжені будь-якими іншими правами третіх осіб та не були набуті з порушенням норм Закону України «Про запобігання та протидію легалізації (відмиванню) доходів, одержаних злочинним шляхом, фінансуванню тероризму та фінансуванню розповсюдження зброї масового знищення»;
                    </Typography>
                </ListItem>
                <ListItem>
                    <Typography variant="body1" align="justify">
                        не порушувати, не блокувати або іншим чином не завдавати шкоди будь-яким засобам безпеки Платформи;
                    </Typography>
                </ListItem>
                <ListItem>
                    <Typography variant="body1" align="justify">
                        не вживати ніяких дій, які можуть призвести до непропорційно великого навантаження на інфраструктуру Платформи;
                    </Typography>
                </ListItem>
                <ListItem>
                    <Typography variant="body1" align="justify">
                        не використовувати автоматичні програми для отримання доступу до інформації Платформи;
                    </Typography>
                </ListItem>
                <ListItem>
                    <Typography variant="body1" align="justify">
                        не здійснювати будь-які дії з порушенням рекомендацій і політик Компанії, використовувати програмні віруси або будь-які інші комп’ютерні коди, файли або програми, які розроблені або призначені для порушення, пошкодження, обмеження або втручання в роботу будь-якого програмного забезпечення, обладнання, або телекомунікаційного обладнання, або для пошкодження, або отримання несанкціонованого доступу до будь-якої системи, даних, паролів та іншої інформації Компанії або третьої сторони;
                    </Typography>
                </ListItem>
                <ListItem>
                    <Typography variant="body1" align="justify">
                        не використовувати Платформу для фішингу та шахрайських цілей;
                    </Typography>
                </ListItem>
                <ListItem>
                    <Typography variant="body1" align="justify">
                        не вчиняти інші протиправні дії, включаючи отримання несанкціонованого доступу до конфіденційної інформації, її розповсюдження, неправомірний доступ до комп’ютерної інформації, використання та розповсюдження шкідливих комп’ютерних програм;
                    </Typography>
                </ListItem>
                <ListItem>
                    <Typography variant="body1" align="justify">
                        не декомпілювати, дизасемблювати, реконструювати або іншим чином намагатися отримати будь-який код Платформи або лежачі в основі ідеї або алгоритми будь-якої частини Платформи, за винятком випадків, якщо дійсні закони прямо забороняють таке обмеження;
                    </Typography>
                </ListItem>
                <ListItem>
                    <Typography variant="body1" align="justify">
                        не модифікувати, перекладати чи іншим чином створювати похідні роботи будь-якої частини Платформи;
                    </Typography>
                </ListItem>
                <ListItem>
                    <Typography variant="body1" align="justify">
                        обходити або намагатися обійти будь-які заходи, які Компанія може використовувати для запобігання або обмеження доступу до Платформи (або інших облікових записів, комп’ютерних систем та/або мереж, підключених до Платформи).
                    </Typography>
                </ListItem>
            </List>
            <Typography variant="h5" align="justify" gutterBottom>
                6. Гарантії.
            </Typography>
            <Typography variant="body1" align="justify" gutterBottom>
                6.1. Доступ до Платформи надається «ЯК Є», «ЯК ДОСТУПНО»,  «В ТОМУ ВИГЛЯДІ, В ЯКОМУ ВОНА ІСНУЄ», однак Компанія робить все можливе та вживає всіх розумно можливих технічних та юридичних заходів, щоб забезпечити безпечне використання Платформи Користувачами та забезпечує безпеку персональним даним, які надає кожен Користувач під час використання Платформи.
            </Typography>
            <Typography variant="body1" align="justify" gutterBottom>
                6.2. Компанія не гарантує, що: (1) Платформа відповідає чи відповідатиме вимогам та очікуванням Користувача; (2) Платформа буде доступна безперервно, доступ до якої буде надаватись швидко, надійно та без помилок; (3) Платформа не містить жодних помилок, упущень, переривань, видалень, описок або інших дефектів.
            </Typography>
            <Typography variant="h5" align="justify" gutterBottom>
                7. Обмеження відповідальності.
            </Typography>
            <Typography variant="body1" align="justify" gutterBottom>
                7.1. Компанія та Користувач несуть відповідальність за виконання зобов'язань за цим Договором відповідно до чинного законодавства України.
            </Typography>
            <Typography variant="body1" align="justify" gutterBottom>
                7.2. Максимальною мірою, що дозволено чинним законодавством, Компанія не несе жодної відповідальності за (1) невідповідність функціонала Платформи очікуванням Користувача, (2) помилки й збої в роботі Платформи, (3) друкарські помилки, неточності, упущення або інші недоліки в Платформі, а також несвоєчасність або недостовірність будь-якої інформації, що міститься на Платформі, (4) будь-які прямі, непрямі, побічні, фактичні або випадкові збитки, які понесли або можуть понести Користувачі в результаті тимчасового призупинення роботи Платформи, (5) безпечність функціонування та контент сторонніх вебсайтів, посилання на які можуть міститись на Платформі, (6) збитки або шкоду, що спричинені внаслідок порушення умов Договору іншим Користувачем, (7) відсутність належного зʼєднання до мережі Інтернет у Користувача, внаслідок чого у Користувача будуть спостерігатись складнощі з доступом до Платформи.
            </Typography>
            <Typography variant="body1" align="justify" gutterBottom>
                7.3. Компанія не може контролювати та не контролює дотримання Користувачем умов цього Договору, та не несе жодної відповідальності в разі порушення Користувачем положень цього Договору, вимог законодавства України.
            </Typography>
            <Typography variant="body1" align="justify" gutterBottom>
                7.4. Компанія не несе відповідальності за злочинні, незаконні, недбалі дії чи бездіяльність третіх осіб, пов’язаних з використанням Платформи.
            </Typography>
            <Typography variant="body1" align="justify" gutterBottom>
                7.5. Компанія не несе відповідальності за прийняття рішень Користувачем про придбання Об'єктів та всі повʼязані з ними ризики та наслідки. Придбання Об'єкта не створює для Володільця жодних юридичних наслідків щодо відповідного обʼєкту в житті.
            </Typography>
            <Typography variant="body1" align="justify" gutterBottom>
                7.6. Компанія не несе відповідальності перед фізичними та/або юридичними особами, інтереси та права яких можуть бути порушені у звʼязку із придбанням Обʼєктів.
            </Typography>
            <Typography variant="body1" align="justify" gutterBottom>
                7.7. У разі пред'явлення третіми особами будь-яких претензій до Компанії, що виникли з вини Користувача, винна сторона зобов'язується співпрацювати з Компанією в межах захисту Компанії за такими претензіями та своїми силами й за свій рахунок врегулювати такі претензії з третіми особами, захистивши Компанію від можливих спорів, збитків, відшкодувань шкоди, штрафних санкцій, інших додаткових витрат.
            </Typography>
            <Typography variant="h5" align="justify" gutterBottom>
                8. Право власності на Обʼєкти.
            </Typography>
            <Typography variant="body1" align="justify" gutterBottom>
                8.1. Вартість Обʼєкта, який ще не знаходиться у Володінні жодного із Користувачів, встановлюється Компанією на власний розсуд. Наразі мінімальна ціна Об’єкта складає100 гривень(розмір Об’єкта 1 клітина), максимальна 202,500 гривень(розмір Об’єкта 45 *45 клітин).
            </Typography>
            <Typography variant="body1" align="justify" gutterBottom>
                8.2. Права Володільців на Обʼєкти не є «правом власності», а надаються Компанією зареєстрованим Користувачам, які придбали на Платформі Обʼєкти, для розпорядження, що обмежується виключно функціональними можливостями Платформи та положеннями Договору.
            </Typography>
            <Typography variant="body1" align="justify" gutterBottom>
                8.3. Віртуальні обʼєкти, доступ до яких надається Користувачам після придбання їх на Платформі, що визначаються як «Обʼєкти», не несуть під собою жодних прав володіння, користування, розпорядження населеними пунктами, земельними ділянками, капітальними спорудами, об'єктами транспортної інфраструктури, обʼєктами критичної інфраструктури та іншими обʼєктами в межах території російської федерації або будь-які інші відчужувані права. Обʼєкти були створені та використовуються виключно в межах Платформи.
            </Typography>
            <Typography variant="body1" align="justify" gutterBottom>
                8.4. Права на Обʼєкти, які знаходяться у Вашому Володінні та закріплені за Вашим Особистим кабінетом, не дозволяється передавати іншим Користувачам Платформи, а також використовувати в цілях, що не передбачені Договором, а також поза межами Платформи.
            </Typography>
            <Typography variant="body1" align="justify" gutterBottom>
                8.5. Відомості про Володільців Обʼєктів не мають жодного відношення до даних, які містяться в офіційних державних реєстрах речових прав на нерухоме майно, відомостей про власників земельних ділянок, що містяться в Кадастровій карті та в інших офіційних ресурсах.
            </Typography>
            <Typography variant="h5" align="justify" gutterBottom>
                9. Права інтелектуальної власності.
            </Typography>
            <Typography variant="body1" align="justify" gutterBottom>
                9.1. Програмний код, елементи дизайну, текст, графічні зображення, ілюстрації, програмне забезпечення, інші об'єкти інтелектуальної власності, а також сама Платформа є об'єктами права інтелектуальної власності, що належать Компанії та/або їх законним правовласникам та охороняються законодавством України про інтелектуальну власність, а також відповідними міжнародними угодами й конвенціями.
            </Typography>
            <Typography variant="body1" align="justify" gutterBottom>
                9.2. Використання Платформи дозволено тільки в рамках запропонованого функціоналу. Ніякі елементи, контент, розміщені на Платформі, не можуть бути використані в інший спосіб без попереднього дозволу Компанії. Під забороненим використанням маються на увазі, в тому числі, але не обмежуючись наступним: відтворення, копіювання, перероблення, адаптація, перепродаж, розміщення, поширення будь-яким способом тощо.
            </Typography>
            <Typography variant="h5" align="justify" gutterBottom>
                10. Захист персональних даних.
            </Typography>
            <Typography variant="body1" align="justify" gutterBottom>
                10.1. Персональні дані є частиною конфіденційної інформації та надаються Користувачем виключно на добровільній основі й за його згоди при користуванні Платформою.
            </Typography>
            <Typography variant="body1" align="justify" gutterBottom>
                10.2. Компанія здійснює збір, зберігання і використання та обробку персональних даних Користувачів та Володільців відповідно до цього Договору та Правил збору та обробки даних Компанії. Компанія вживає всіх необхідних заходів для захисту конфіденційності персональних даних Користувачів згідно з порядком, який зазвичай використовується для захисту такого роду інформації в існуючому діловому обороті.
            </Typography>
            <Typography variant="h5" align="justify" gutterBottom>
                11. Порядок вирішення спорів.
            </Typography>
            <Typography variant="body1" align="justify" gutterBottom>
                11.1. Договір регулюється та тлумачиться відповідно до чинного законодавства України.
            </Typography>
            <Typography variant="body1" align="justify" gutterBottom>
                11.2. Усі спори та розбіжності, які можуть виникнути у звʼязку з цим Договором, мають вирішуватися шляхом переговорів. У випадку неможливості вирішення спорів шляхом переговорів, будь-які спори, розбіжності або питання, що виникають з цього Договору або пов'язані з ним, підлягають остаточному врегулюванню відповідно до чинного законодавства України.
            </Typography>
            <Typography variant="h5" align="justify" gutterBottom>
                12. Внесення змін до Договору.
            </Typography>
            <Typography variant="body1" align="justify" gutterBottom>
                12.1. Для поліпшення роботи Платформи Компанія може вносити зміни / доповнення до цього Договору без попереднього сповіщення Користувачів. Датою вступу в силу нової редакції Правил вважається дата опублікування Правил на Платформі. Компанія не зобов'язана повідомляти Користувачів про внесені зміни. Для цілей ознайомлення з новими умовами, Користувач повинен періодично ознайомлюватися з умовами Договору, зокрема і перед початком проходження процедури Реєстрації.
            </Typography>
            <Typography variant="body1" align="justify" gutterBottom>
                12.2. У разі незгоди Користувача та/або Володільця з новою редакцією Договору, Користувач та/або Володілець має припинити використання Платформи.
            </Typography>
            <Typography variant="h5" align="justify" gutterBottom>
                13. Заключні положення.
            </Typography>
            <Typography variant="body1" align="justify" gutterBottom>
                13.1. Якщо будь-яке положення цього Договору виявиться таким, що не має законної сили або буде визнано недійсним, це положення буде обмежено в дії або виключено у мінімальній мірі, яка необхідна для того, щоб цей Договір в іншому залишився в повній силі, діяв та підлягав подальшому виконанню. Нездатність будь-якої зі сторін скористатися будь-яким правом, не вважається відмовою від будь-яких інших додаткових прав за цим Договором. Нездатність Компанії забезпечити дотримання будь-якої частини цього Договору не є відмовою від права Компанії на подальше застосування такої або будь-якої іншої частини цього Договору. Відмова від дотримання будь-якого пункту, в будь-якому конкретному випадку не означає, що Компанія відмовилася від дотримання такого пункту у майбутньому.
            </Typography>
            <Typography variant="body1" align="justify" gutterBottom>
                13.2. У разі незгоди з будь-якими положеннями цього Договору або виникнення додаткових запитань чи в інших випадках, визначених цим Договором, Користувач та Володілець можуть зв'язатися із Компанією за адресою електронної пошти: klaptuk.peremogu@gmail.com. Контактний телефон 0500695899
            </Typography>
            <Typography variant="body1" align="justify" gutterBottom>
                13.3. Цей Договір укладений українською мовою, не вимагає двостороннього підписання та дійсний для сторін в електронному вигляді.
            </Typography>
        </DialogContent>
    </Dialog>
}

export default PublicOfferModal;
