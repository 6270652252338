import React, {createContext, useContext, useEffect, useState} from "react";
import axios from "axios";
import {getErrorMessage, PLOTS_GET_ALL} from "../util/util";
import {useLoader} from "./Loader";
import toast from "react-hot-toast";
import {Grid} from "../util/gridUtil"
import {featureCollection} from "@turf/turf";
import Decimal from "decimal.js";


const PlotContext = createContext<PlotContextValue | null>(null)

const usePlot = (): PlotContextValue => {
    const context = useContext(PlotContext)
    if (!context) throw new Error('usePlot must be used inside PlotContext')
    return context
}

type Props = {
    children: any[] | any[][]
}

type PlotContextValue = {
    sold: Grid[],
    selection: Grid | null,
    setSelection: (n: Grid | null) => void,
    orderOpen: boolean,
    setOrderOpen: (n: boolean) => void,
    loadPlots: () => void
}

/**
 * Формат в котором данные об участках хранятся на сервере
 */
type PlotFetchType = {
    id: number
    min_x: string
    min_y: string
    max_x: string
    max_y: string
    color: string
    active: boolean
    created_at: string
    updated_at: string
}
/**
 * Загрузка купленных блоков
 * Сохранение выделенных блоков
 * Управление состоянием окна заказа
 */
const PlotContextProvider = ({children}: Props) => {
    const [sold, setSold] = useState<Grid[]>([])
    const [selection, setSelection] = useState<Grid | null>(null)
    const [orderOpen, setOrderOpen] = useState<boolean>(false)
    const {setLoading} = useLoader()

    const loadPlots = () => {
        axios.get(PLOTS_GET_ALL)
            .then(r => setSold(r.data.map((it: PlotFetchType) => {
                return new Grid(featureCollection([]), [
                    new Decimal(it.min_x).toNumber(), new Decimal(it.min_y).toNumber(),
                    new Decimal(it.max_x).toNumber(), new Decimal(it.max_y).toNumber()
                ], it.min_y + it.min_x + it.max_x + it.max_y, it)
            })))
            .catch(e => toast.error(getErrorMessage(e)))
            .finally(() => setLoading(false))
    }

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        loadPlots()
    }, [])

    const value: PlotContextValue = {sold, selection, setSelection, orderOpen, setOrderOpen, loadPlots}

    return <PlotContext.Provider children={children} value={value}/>
}

export default PlotContextProvider
export {usePlot}
