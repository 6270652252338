import React, {useState} from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    TextField,
    ToggleButton,
    Typography
} from "@mui/material";
import {DoneOutlined} from "@mui/icons-material";
import toast from "react-hot-toast";
import OrderSentModal from "./OrderSentModal";
import {useLoader} from "../../context/Loader";
import axios from "axios";
import {CREATE_ORDER, emailValid, getCurrentLanguage, getErrorMessage, SQUARE_PRICE} from "../../util/util";
import {useGoogleReCaptcha} from "react-google-recaptcha-v3";
import {usePlot} from "../../context/PlotContext";
import {useTranslation} from "react-i18next";

const availableColors = [
    '#f5f0e0',
    '#bab4b5',
    '#f07800',
    '#9bb7d4',
    '#926aa6',
    '#b55a30',
    '#00a170',
    '#d2386c',
    '#efe1ce',
    '#9a8b4f',
    '#a0daa9'
];

const OrderModal = () => {
    const [sentOpen, setSentOpen] = useState<boolean>(false)

    const [color, setColor] = useState<string>('')
    const [name, setName] = useState<string>('')
    const [email, setEmail] = useState<string>('')
    const [message, setMessage] = useState<string>('')
    //форма для оплаты
    const [form, setForm] = useState<string>('')

    const {setLoading} = useLoader()
    const {loadPlots} = usePlot()

    const {orderOpen, setOrderOpen, selection} = usePlot()

    const {executeRecaptcha} = useGoogleReCaptcha()

    const {t} = useTranslation()

    const closeSentModal = () => {
        setSentOpen(false)
        clear()
    }

    const clear = () => {
        setColor('')
        setName('')
        setEmail('')
        setMessage('')
    }

    const send = () => {
        if (name.length < 1) {
            toast.error(t('order.enter_name'))
            return
        }
        if (email.length < 4 || !emailValid(email)) {
            toast.error(t('order.enter_email'))
            return
        }
        if (color.length === 0) {
            toast.error(t(t('order.enter_color')))
            return
        }
        setLoading(true)
        if (executeRecaptcha) {
            executeRecaptcha('newOrder').then(key => {
                axios.post(CREATE_ORDER, {
                    color,
                    name,
                    email,
                    message,
                    min: selection!!.coordinates.slice(0, 2),
                    max: selection!!.coordinates.slice(2),
                    captcha: key,
                    language: getCurrentLanguage()
                }).then(res => {
                    setSentOpen(true)
                    setOrderOpen(false)
                    setForm(res.data.form)
                    loadPlots()
                }).catch(res => {
                    toast.error(getErrorMessage(res))
                }).finally(() => setLoading(false))
            }).catch(() => {
                toast.error(t('captcha_error'))
                setLoading(false)
            })
        } else {
            toast.error(t('captcha_error'))
            setLoading(false)
        }
    }

    const close = () => {
        clear()
        setOrderOpen(false)
    }

    return [
        <OrderSentModal key='sent' open={sentOpen} email={email} close={closeSentModal} form={form}/>,
        <Dialog key='main' open={orderOpen} onClose={close}>
            <DialogTitle sx={{display: 'flex', justifyContent: 'center'}}>
                {t('order.size', {count: selection?.grid?.features?.length})}
            </DialogTitle>
            <DialogContent sx={{textAlign: 'center'}}>
                <Grid spacing={1} container direction='column'>
                    <Grid item>
                        <Typography>
                            {t('order.total')}:
                        </Typography>
                        <Typography fontWeight='bold'>
                            {(selection !== null && selection.grid !== null) && selection.grid!.features.length * SQUARE_PRICE}
                            {' '}
                            {t('uah')}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <TextField required error={email.length !== 0 && !emailValid(email)}
                                   sx={{width: '240px'}} value={email} onChange={e => setEmail(e.target.value)}
                                   size='small'
                                   label={t('email')} type='email'/>
                    </Grid>
                    <Grid item>
                        <TextField required sx={{width: '240px'}}
                                   value={name}
                                   inputProps={{maxLength: 20, minLength: 1}}
                                   onChange={e => setName(e.target.value)}
                                   size='small'
                                   label={t('name')}
                                   type='text'/>
                    </Grid>
                    <Grid item sx={{marginBottom: '25px'}}>
                        <TextField sx={{width: '240px'}} multiline rows={2} value={message}
                                   inputProps={{maxLength: 150}}
                                   onChange={e => setMessage(e.target.value)}
                                   size='small'
                                   label={t('order.enter_message')} type='text'/>
                    </Grid>
                    <Grid item>
                        <Typography>{t('order.enter_color')} *</Typography>
                    </Grid>
                    <Grid item>
                        <Grid maxWidth='300px' spacing='4px' display='flex' justifyContent='center' container>
                            {availableColors.map(it => {
                                return <Grid item key={it}>
                                    {/** @ts-ignore */}
                                    <ToggleButton onClick={e => setColor(e.target.value)}
                                                  size='small'
                                                  sx={{
                                                      width: '35px',
                                                      height: '35px',
                                                      backgroundColor: it,
                                                      border: color === it ? 'solid 2px' : '',
                                                      "&:hover": {
                                                          backgroundColor: it,
                                                          border: 'solid 2px'
                                                      }
                                                  }}
                                                  value={it}
                                    >
                                        {color === it && <DoneOutlined/>}
                                    </ToggleButton>
                                </Grid>
                            })}
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={close}>{t('order.cancel')}</Button>
                <Button onClick={send}>{t('order.pay')}</Button>
            </DialogActions>
        </Dialog>
    ]
}

export default OrderModal
