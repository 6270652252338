import React from "react";
import { useTranslation } from "react-i18next";
import {
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    Link,
    List,
    ListItem,
    Typography
} from "@mui/material";
import { Close } from "@mui/icons-material";

interface PrivatePolicyProps {
    open: boolean,
    close: () => void
}

const PrivatePolicyModal = ({open, close}: PrivatePolicyProps) => {
    const {t} = useTranslation();

    return <Dialog
        fullWidth
        open={open}
        onClose={close}
    >
        <DialogTitle>
            {t('private_policy_title')}
            <IconButton
                onClick={close}
                size="small"
                sx={{
                    position: 'absolute',
                    right: '10px',
                    top: '10px'
                }}
            >
                <Close/>
            </IconButton>
        </DialogTitle>
        <DialogContent>
            <Typography variant="body1" gutterBottom>
                Політику опубліковано: 18.08.2023
            </Typography>
            <Typography variant="body1" gutterBottom>
                Поточна редакція: 1
            </Typography>
            <Typography variant="body1" align="justify" gutterBottom>
                Ми з великою повагою та турботою ставимось до кожного Користувача Платформи, а тому приділяємо особливу увагу захисту приватності Користувачів і безпеці Обробки їх Персональних Даних, тим самим маємо на меті створити і забезпечити максимально комфортні умови використання Платформи для кожного.
            </Typography>
            <Typography variant="body1" align="justify" gutterBottom>
                Дотримання конфіденційного порядку поводження із Персональними Даними має важливе значення для Компанії, оскільки метою Політики Збору та Обробки Даних є забезпечення прав і свобод Користувачів при Обробці його Персональних Даних, у тому числі захисту прав на недоторканність, зокрема від несанкціонованого доступу і розголошення Персональних Даних.
            </Typography>
            <Typography variant="body1" align="justify" gutterBottom>
                Ми розробили цю Політику Збору та Обробки Даних, щоб допомогти Вам зрозуміти, як Ми Обробляємо та захищаємо Ваші Персональні Дані, коли Ви використовуєте Платформу.
            </Typography>
            <Typography variant="body1" align="justify" gutterBottom>
                Політика Збору та Обробки Даних розроблена та діє з урахуванням Загального регламенту про захист даних (General Data Protection Regulation (GDPR)), Загального регламенту про захист даних Великої Британії (UK GDPR), Закону штату Каліфорнія, США “Про конфіденційність споживачів” від 2018 року, Закону штату Каліфорнія, США “Про захист конфіденційності в Інтернеті” від 2003 року, Закону України “Про захист персональних даних”, а також інших нормативно-правових актів і практик із захисту Персональних Даних.
            </Typography>
            <Typography variant="body1" align="justify">
                Під час реєстрації облікового запису на Платформі шляхом проставлення відмітки у відповідному чек-боксі або авторизації за допомогою сторонніх сервісів, Ви підтверджуєте, що Ви:
            </Typography>
            <List>
                <ListItem>
                    <Typography variant="body1" align="justify">
                        - належним чином ознайомились із порядком, метою та іншими умовами Обробки Персональних Даних, що викладені в Політиці Збору та Обробки Даних;
                    </Typography>
                </ListItem>
                <ListItem>
                    <Typography variant="body1" align="justify">
                        - надаєте Згоду на збір, Обробку та використання Ваших Персональних Даних згідно з умовами цієї Політики;
                    </Typography>
                </ListItem>
                <ListItem>
                    <Typography variant="body1" align="justify">
                        - маєте достатню правоздатність та дієздатність за законами Вашої країни для надання Згоди з умовами цієї Політики.
                    </Typography>
                </ListItem>
            </List>
            <Typography variant="body1" align="justify" gutterBottom>
                Ми просимо Вас ретельно ознайомитися з умовами Політики Збору та Обробки Даних перед використанням Платформи. Якщо Ви не Згодні, або не можете підтвердити зазначене, Ви повинні негайно припинити використання Платформи. У такому разі Ви повинні (а) зв’язатися з Нами й подати запит на видалення Ваших Даних; (б) залишити Платформу та не використовувати її.
            </Typography>
            <Typography variant="body1" align="justify" gutterBottom>
                1. Основні терміни, які використовуються в Політиці.
            </Typography>
            <Typography variant="body1" align="justify" gutterBottom>
                1.1. Політика Збору та Обробки Даних – цей документ (“Політика”).
            </Typography>
            <Typography variant="body1" align="justify" gutterBottom>
                1.2. Компанія - ФОП Саласенко Сергій Виталійович, номер запису про проведення державної реєстрації: 10000000103315 від 08.05.2020 (далі – “Компанія”, “Ми”, “Нас”, “Наші”).
            </Typography>
            <Typography variant="body1" align="justify" gutterBottom>
                1.3. Платформа – вебсайт за посиланням: <Link href="https://klaptuk.com/" target="_blank" rel="noreferrer">https://klaptuk.com/</Link>, та весь контент, що міститься у ньому.
            </Typography>
            <Typography variant="body1" align="justify" gutterBottom>
                1.4. Послуги - інформаційні послуги Компанії, які полягають у наданні Користувачу можливості “придбати” віртуальні обʼєкти в межах території російської федерації на онлайн-мапі через Платформу, доступу до них в особистому кабінеті Користувача на Платформі та забезпеченні можливості розпоряджатись обʼєктами відповідно до умов, що викладені в договорі Публічної Оферти.
            </Typography>
            <Typography variant="body1" align="justify" gutterBottom>
                1.5. Суб’єкт Персональних Даних – особа, яка відвідує та/або користується Платформою, стосовно якої здійснюється Обробка її Персональних Даних (“Користувач”, “Ви”, “Вас”, “Вам”).
            </Typography>
            <Typography variant="body1" align="justify" gutterBottom>
                1.6. Персональні Дані – будь-яка інформація, що стосується фізичної особи, яку ідентифіковано чи можна ідентифікувати за допомогою цієї інформації (“Персональні Дані”, “Дані”).
            </Typography>
            <Typography variant="body1" align="justify" gutterBottom>
                1.7. Обробка Персональних Даних – будь-яка операція або низка операцій з Персональними Даними або наборами Персональних Даних з використанням автоматизованих засобів або без них, такі як збирання, реєстрація, організація, структурування, зберігання, адаптація чи зміна, пошук, ознайомлення, використання, розкриття через передавання, розповсюдження чи надання іншим чином, упорядкування чи комбінування, обмеження, стирання чи знищення.
            </Typography>
            <Typography variant="body1" align="justify" gutterBottom>
                1.8. Володілець (Контролер) Персональних Даних – Компанія, яка визначає цілі та засоби Обробки Персональних Даних, встановлює склад цих Даних і процедури їх Обробки.
            </Typography>
            <Typography variant="body1" align="justify" gutterBottom>
                1.9. Розпорядник (Процесор) Персональних Даних – Компанія або інші особи, яким Володільцем Персональних Даних або законом надано право Обробляти Персональні Дані від імені Володільця.
            </Typography>
            <Typography variant="body1" align="justify" gutterBottom>
                1.10.   Згода – будь-яке вільно надане, конкретне, поінформоване та однозначне зазначення бажання Суб’єкта Персональних Даних, яким він чи вона шляхом чітких ствердних дій підтверджує згоду на Обробку своїх Персональних Даних.
            </Typography>
            <Typography variant="body1" align="justify" gutterBottom>
                1.11. Загальний регламент про захист даних – Регламент Європейського Парламенту та Ради № 2016/679 від 27.04.2016 про захист фізичних осіб у звʼязку з опрацюванням персональних даних і про вільний рух таких даних, та про скасування Директиви 95/46/ЄС (далі – “GDPR”).
            </Typography>
            <Typography variant="body1" align="justify" gutterBottom>
                1.12. UK GDPR – регламент, включений до законодавства Сполученого Королівства Великої Британії та Північної Ірландії зі змінами, внесеними згідно з Положенням про захист даних, конфіденційність та електронні комунікації.
            </Typography>
            <Typography variant="body1" align="justify" gutterBottom>
                1.13. CCPA – Закон штату Каліфорнія, США “Про конфіденційність споживачів” від 2018 року.
            </Typography>
            <Typography variant="body1" align="justify" gutterBottom>
                1.14. CalOPPA – Закон штату Каліфорнія, США “Про захист конфіденційності в Інтернеті” від 2003 року.
            </Typography>
            <Typography variant="body1" align="justify" gutterBottom>
                2. Які Персональні Дані Ми Обробляємо?
            </Typography>
            <Typography variant="body1" align="justify" gutterBottom>
                2.1. Персональні Дані, які Ви надаєте Нам особисто:
            </Typography>
            <Typography variant="body1" align="justify">
                Ми збираємо та Обробляємо Персональні Дані, які надаються Користувачем шляхом заповнення спеціальної форми під час проходження процедури реєстрації облікового запису на Платформі, до яких належить:
            </Typography>
            <List>
                <ListItem>
                    <Typography variant="body1" align="justify">
                        - імʼя особи;
                    </Typography>
                </ListItem>
                <ListItem>
                    <Typography variant="body1" align="justify">
                       - електронна адреса.
                    </Typography>
                </ListItem>
            </List>
            <Typography variant="body1" align="justify" gutterBottom>
                Якщо Користувач вирішив увійти на Платформу, використовуючи службу автентифікації стороннього оператора, наприклад Facebook або Google, Компанія може отримати Персональні Дані Користувача, або іншу інформацію, доступ до якої надано такою третьою особою.
            </Typography>
            <Typography variant="body1" align="justify" gutterBottom>
                За потреби Ми можемо запросити у Вас додаткову інформацію для належного надання Послуг Компанією. Компанія збирає тільки ті Персональні Дані, які свідомо і добровільно надані Вами як Суб'єктом Персональних Даних в цілях використання Платформи, або інформацію, що містилась в будь-яких повідомленнях, які Ви надсилали нам електронною поштою при зверненні, включаючи зміст повідомлення та мета дані.
            </Typography>
            <Typography variant="body1" align="justify" gutterBottom>
                Звертаємо Вашу увагу, що Компанія обмежується збором мінімального обсягу інформації, необхідного виключно для належного надання Послуг Користувачу.
            </Typography>
            <Typography variant="body1" align="justify" gutterBottom>
                2.2. Дані, які Ми Обробляємо в автоматичному режимі.
            </Typography>
            <Typography variant="body1" align="justify" gutterBottom>
                Перелік Персональних Даних, які Ми автоматично отримуємо від Вас під час використання Платформи, включає:
            </Typography>
            <List>
                <ListItem>
                    <Typography variant="body1" align="justify">
                        - інформацію про Ваш пристрій;
                    </Typography>
                </ListItem>
                <ListItem>
                    <Typography variant="body1" align="justify">
                        - інформація про з'єднання, трафік, дату і час тривалості відвідування Платформи
                    </Typography>
                </ListItem>
            </List>
            <Typography variant="body1" align="justify" gutterBottom>
                3. Чиї Персональні Дані Ми не Обробляємо?
            </Typography>
            <Typography variant="body1" align="justify" gutterBottom>
                Ми свідомо не Обробляємо Персональні Дані дітей до 13 років (застосовується до Користувачів, які є резидентами країн, у яких встановлено знижений мінімальний вік на Обробку Персональних Даних) та дітей до 16 років, а також не пропонуємо їм користуватися Платформою.
            </Typography>
            <Typography variant="body1" align="justify" gutterBottom>
                Якщо Ми дізнаємося, що особа, котра не досягла віку, з якого дозволено Обробляти її Персональні Дані, надала Нам свої Персональні Дані без Згоди законного представника, опікуна чи іншої особи, уповноваженої на надання такої Згоди, Ми будемо вживати всіх необхідних заходів для видалення таких Персональних Даних.
            </Typography>
            <Typography variant="body1" align="justify" gutterBottom>
                4. Цілі Обробки Персональних Даних.
            </Typography>
            <Typography variant="body1" align="justify" gutterBottom>
                Ми Обробляємо надані Вами Персональні Дані лише задля конкретних та обмежених цілей, що зазначені нижче:
            </Typography>
            <Typography variant="body1" align="justify" gutterBottom>
                Надання Послуг. Ми використовуємо надані Вами Дані для того, щоб забезпечити доступ до віртуальних обʼєктів, які Ви придбали на Платформі, та можливість розпоряджатись ними у порядку та межах, що встановлені договором Публічної Оферти.
            </Typography>
            <Typography variant="body1" align="justify" gutterBottom>
                Підтримка Користувачів. У разі виникнення проблем або складнощів у користуванні Платформою, Ми можемо використовувати надані Вами Персональні Дані з метою надання ефективної клієнтської та технічної підтримки.
            </Typography>
            <Typography variant="body1" align="justify" gutterBottom>
                Врегулювання претензій. Ми використовуємо Персональні Дані з метою ефективного та своєчасного врегулювання претензій від Користувачів, що виникли у зв'язку з використанням Платформи.
            </Typography>
            <Typography variant="body1" align="justify" gutterBottom>
                Управління та поліпшення Платформи. Увесь час Ми намагаємось удосконалювати функції, що підтримують роботу Платформи, та додавати нові можливості. Це дозволяє Користувачам безперебійно, швидко та якісно використовувати Платформу.
            </Typography>
            <Typography variant="body1" align="justify" gutterBottom>
                Розробка й підтримка безпеки Платформи. Ми відстежуємо збої в роботі Платформи та створюємо програмні рішення для їх усунення, виявляємо порушення цієї Політики, а також здійснюємо моніторинг використання, зловживання й потенційного зловживання Платформою.
            </Typography>
            <Typography variant="body1" align="justify" gutterBottom>
                Надсилання повідомлень та сповіщень щодо Платформи. З метою підтвердження реєстрації на Платформі, Компанія відправляє Користувачу лист на електронну пошту, який містить посилання, по якому слід перейти аби підтвердити та завершити процедуру реєстрації. Також за нагальної потреби, яка визначається Компанією на власний розсуд, за наявності суттєвих змін у роботі Платформи, виникнення загрози порушенню прав Користувачів тощо, Компанія може використовувати електронну пошту Користувачів з метою повідомлення важливої інформації.
            </Typography>
            <Typography variant="body1" align="justify" gutterBottom>
                5. Юридичні підстави для Обробки Ваших Персональних Даних.
            </Typography>
            <Typography variant="body1" align="justify" gutterBottom>
                Для Обробки Ваших Персональних Даних Ми керуємось такими юридичними підставами:
            </Typography>
            <List>
                <ListItem>
                    <Typography variant="body1" align="justify">
                        1. Ваша Згода на таку Обробку;
                    </Typography>
                </ListItem>
                <ListItem>
                    <Typography variant="body1" align="justify">
                        2. Укладення та виконання правочину, стороною якого Ви є;
                    </Typography>
                </ListItem>
                <ListItem>
                    <Typography variant="body1" align="justify">
                        3. Захист Ваших життєво важливих інтересів;
                    </Typography>
                </ListItem>
                <ListItem>
                    <Typography variant="body1" align="justify">
                        4. Необхідність виконання обов’язку Володільця Персональних Даних, який передбачений законом;
                    </Typography>
                </ListItem>
                <ListItem>
                    <Typography variant="body1" align="justify">
                        5. Законні інтереси, що переслідуються Нами, за умови, що такі законні інтереси не переважатимуть над Вашими інтересами або основними правами та свободами, які вимагають захисту Персональних Даних (стаття 6 (1) (f) GDPR / UK GDPR). Ми покладаємося на законні інтереси:
                        <List>
                            <ListItem>
                                <Typography variant="body1" align="justify">
                                    - для надсилання Вам важливих повідомлень, що стосуються роботи Платформи, змін до Політики або договору публічної оферти, або з інших причин, які можуть бути актуальними для Вас;
                                </Typography>
                            </ListItem>
                            <ListItem>
                                <Typography variant="body1" align="justify">
                                    - для забезпечення виконання договору публічної оферти, а також для запобігання й боротьби з шахрайством.
                                </Typography>
                            </ListItem>
                        </List>
                    </Typography>
                </ListItem>
            </List>
            <Typography variant="body1" align="justify" gutterBottom>
                6. Як Ми зберігаємо та Обробляємо Ваші Персональні Дані?
            </Typography>
            <Typography variant="body1" align="justify" gutterBottom>
                Ми зберігатимемо Ваші Персональні Дані стільки часу, скільки це обґрунтовано необхідно для досягнення цілей, викладених у цій Політиці (включно з наданням Вам Послуг). Ми також зберігатимемо Ваші Персональні Дані, якщо це буде необхідно для дотримання наших юридичних зобов’язань, а також до моменту відкликання Згоди з боку Користувача.
            </Typography>
            <Typography variant="body1" align="justify" gutterBottom>
                Ваші Персональні Дані, які Ми Обробляємо, можуть бути передані та зберігатися поза межами України, зокрема в межах Європейського економічного простору (ЄЕП).
            </Typography>
            <Typography variant="body1" align="justify" gutterBottom>
                Ми будемо вживати всіх необхідних заходів, щоб гарантувати виконання всіх відповідних заходів безпеки Ваших Даних.</Typography>
            <Typography variant="body1" align="justify" gutterBottom>
                Обробка Персональних Даних здійснюється з використанням комп'ютерів та/або автоматизованих засобів відповідно до процедур і методів, які відповідають цілям збору Персональних Даних.</Typography>
            <Typography variant="body1" align="justify" gutterBottom>
                Ми дуже серйозно ставимося до захисту Ваших Персональних Даних. Ми здійснюємо всі комерційні обґрунтовані заходи для запобігання несанкціонованому доступу до Ваших Персональних Даних і тих даних, що були отримані в процесі користування Платформою. Ми прагнемо захистити Ваші Персональні Дані різними засобами з метою збереження їхньої конфіденційності та цілісності, запобігання несанкціонованому використанню або розголошенню Ваших Персональних Даних і захисту Ваших Персональних Даних від їх втрати, неправильного використання, розголошення, зміни та/або знищення.
            </Typography>
            <Typography variant="body1" align="justify" gutterBottom>
                Звертаємо Вашу увагу, що жоден спосіб передачі Даних через Інтернет не може давати 100% гарантії запобігання витоку інформації.
            </Typography>
            <Typography variant="body1" align="justify" gutterBottom>
                7. Чи передаємо Ми Ваші Персональні Дані третім особам?
            </Typography>
            <Typography variant="body1" align="justify" gutterBottom>
                Ми не здійснюємо передачу Персональних Даних третім особам, крім випадків, коли така передача є вимогою законодавства, на прохання Суб'єкта Персональних Даних або в інших випадках, викладених в цій Політиці. Ми розуміємо всю цінність Персональних Даних Наших Користувачів, а тому вживаємо всіх можливих заходів для захисту Персональних Даних Користувачів, які останні добровільно і усвідомлено надають Компанії.
            </Typography>
            <Typography variant="body1" align="justify" gutterBottom>
                У окремих випадках, з метою забезпечення належного функціонування Платформи, Персональні Дані можуть бути доступні певним категоріям відповідальних осіб Компанії (посадовим особам), діяльність яких пов'язана із забезпеченням належного функціонування Платформи або стороннім організаціям (таким як незалежні постачальники технічних послуг, поштові організації, постачальники послуг хостингу, компанії – постачальники інформаційних технологій, служби зв'язку), підрядникам та партнерам Компанії, яким Ми можемо доручити виконувати функції Розпорядника Персональних Даних у разі необхідності. Ви можете додатково запитати в Нас перелік вищезазначених осіб.
            </Typography>
            <Typography variant="body1" align="justify" gutterBottom>
                Звертаємо Вашу увагу, що Ми надаємо доступ до Персональних Даних лише тим стороннім компаніям та/або особам, що здатні належним чином гарантувати найвищий рівень захисту, конфіденційності та безпеки Персональних Даних, а також їх використання лише з метою, що не суперечить положенням Політики.
            </Typography>
            <Typography variant="body1" align="justify" gutterBottom>
                Провайдери IT-послуг. Ми можемо залучати інші компанії (в тому числі іноземні, зокрема Сloudflare), що надають адміністративні та ІТ-послуги, зокрема послуги хостингу, послуги CDN, захисту від небажаного трафіку та DDoS—атак, DNS—сервіси, використання хмарних сервісів і баз даних, які Оброблятимуть Персональні Дані Користувача від Нашого імені з метою забезпечення технічної підтримки та належного функціонування Платформи. Такі компанії можуть отримати доступ до Персональних Даних Користувача, виключно якщо це потрібно для здійснення вищезазначених цілей та відповідно до положень угод із цими компаніями про нерозголошення конфіденційної інформації, і такі компанії не мають права використовувати їх для будь-яких інших цілей. Ознайомитися із політикою Обробки Персональних Даних Сloudflare Ви можете за посиланням: <Link href="https://www.cloudflare.com/privacypolicy/" target="_blank" rel="noreferrer">https://www.cloudflare.com/privacypolicy/</Link>.
            </Typography>
            <Typography variant="body1" align="justify" gutterBottom>
                Фінансові установи (банки, платіжні сервіси / інструменти). Ми можемо залучати фінансові установи (в тому числі іноземні), що надають фінансові, банківські послуги, послуги платіжних сервісів / інструментів, для можливості здійснення оплати віртуальних обʼєктів на Платформі. У разі, якщо Ви під час здійснення переказу коштів у розділі “Купити” або інших подібних розділах на Платформі, виконуєте перехід на сайт / форми такої фінансової установи (банку, платіжного сервісу / інструменту), радимо Вам ознайомитися з їхньою політикою Обробки Персональних Даних.
            </Typography>
            <Typography variant="body1" align="justify" gutterBottom>
                Проведення переказів на Платформі здійснюється за допомогою інтернет-еквайрингу від Приват банк. Ознайомитися із політикою Обробки Персональних Даних АТ КБ “ПРИВАТ БАНК” Ви можете за <Link href="https://privatbank.ua/personal-information" target="_blank" rel="noreferrer">цим посиланням</Link>.
            </Typography>
            <Typography variant="body1" align="justify" gutterBottom>
                Компанія не має доступу до Ваших Персональних Даних (номер платіжної картки, CVV код, строк дії картки), які Ви надаєте фінансовим установам при здійсненні переказів коштів через сайти або форми таких установ.
            </Typography>
            <Typography variant="body1" align="justify" gutterBottom>
                Обробка помилок. Ми можемо використовувати інші сервіси для обробки помилок додатку, наприклад, такі як Sentry. Ознайомитися із політикою Обробки Персональних Даних Sentry Ви можете за посиланням: <Link href="https://sentry.io/terms/" target="_blank" rel="noreferrer">https://sentry.io/terms/</Link>.
            </Typography>
            <Typography variant="body1" align="justify" gutterBottom>
                Збереження та обробка логів. Ми можемо використовувати інші сервіси для збереження та обробки логів, наприклад, такі як Loggly. Ознайомитися із політикою Обробки Персональних Даних Loggly Ви можете за посиланням: <Link href="https://www.loggly.com/about/terms-of-use/" target="_blank" rel="noreferrer">https://www.loggly.com/about/terms-of-use/</Link>.
            </Typography>
            <Typography variant="body1" align="justify" gutterBottom>
                Органи влади (України, Європейського Союзу або інших країн). Ми зберігаємо за собою право розголошувати та/або повідомляти будь-які Персональні Дані Користувача, якщо це вимагається будь-яким законом України або Європейського Союзу чи іншим нормативно-правовим актом, чи підтвердженою законною вимогою органу державної влади та/або місцевого самоврядування, з метою дотримання вимог законів України й законодавства Європейського Союзу, захисту цілісності Платформи, для виконання запитів Користувачів, органів державної влади та/або місцевого самоврядування, чи з метою сприяння будь-якому розслідуванню з боку правоохоронних органів чи розслідуванню з метою гарантування громадської (національної) безпеки. У деяких випадках Ми також можемо надати Персональні Дані Користувача органам влади Європейського Союзу (як державним, так і наддержавним) та органам влади інших держав, якщо таке надання Персональних Даних вимагається законом, або в разі офіційного запиту від такого органу влади в рамках розслідування кримінального правопорушення.
            </Typography>
            <Typography variant="body1" align="justify" gutterBottom>
                8. Які Ви маєте права щодо Ваших Персональних Даних?
            </Typography>
            <Typography variant="body1" align="justify">
                Ви, як Субʼєкт Персональних Даних, маєте наступні права:
            </Typography>
            <List>
                <ListItem>
                    <Typography variant="body1" align="justify">
                        - знати про джерела збирання, місцезнаходження своїх Персональних Даних, мету їх Обробки, місцезнаходження Компанії;
                    </Typography>
                </ListItem>
                <ListItem>
                    <Typography variant="body1" align="justify">
                        - отримувати інформацію про умови надання доступу до Персональних Даних, зокрема інформацію про третіх осіб, яким передаються Ваші Персональні Дані;
                    </Typography>
                </ListItem>
                <ListItem>
                    <Typography variant="body1" align="justify">
                        - на доступ до своїх Персональних Даних;
                    </Typography>
                </ListItem>
                <ListItem>
                    <Typography variant="body1" align="justify">
                        - отримувати не пізніше як за тридцять календарних днів з дня надходження запиту, крім випадків, передбачених законом, відповідь про те, чи Обробляються Ваші Персональні Дані, а також отримувати зміст таких Персональних Даних;
                    </Typography>
                </ListItem>
                <ListItem>
                    <Typography variant="body1" align="justify">
                        - пред'являти вмотивовану вимогу Володільцю із запереченням проти Обробки своїх Персональних Даних, у тому числі профайлінгу, з метою надсилання маркетингових, інформаційних матеріалів;
                    </Typography>
                </ListItem>
                <ListItem>
                    <Typography variant="body1" align="justify">
                        - пред'являти вмотивовану вимогу щодо зміни або знищення своїх Персональних Даних будь-яким Володільцем та Розпорядником Персональних Даних, якщо ці Дані Обробляються незаконно чи є недостовірними (“право на забуття”);
                    </Typography>
                </ListItem>
                <ListItem>
                    <Typography variant="body1" align="justify">
                        - на захист своїх Персональних Даних від незаконної Обробки та випадкової втрати, знищення, пошкодження у зв'язку з умисним приховуванням, ненаданням чи несвоєчасним їх наданням, а також на захист від надання відомостей, що є недостовірними чи ганьблять честь, гідність та ділову репутацію фізичної особи;
                    </Typography>
                </ListItem>
                <ListItem>
                    <Typography variant="body1" align="justify">
                        - вимагати перенесення Персональних Даних, якщо Ви бажаєте отримати свої Персональні Дані в форматі, що легко зчитується машиною, та передати такі Дані іншому Контролеру без перешкод;
                    </Typography>
                </ListItem>
                <ListItem>
                    <Typography variant="body1" align="justify">
                        - звертатися із скаргами на Обробку своїх Персональних Даних до Уповноваженого Верховної Ради України з прав людини або до суду;
                    </Typography>
                </ListItem>
                <ListItem>
                    <Typography variant="body1" align="justify">
                        - застосовувати засоби правового захисту в разі порушення законодавства про захист Персональних Даних;
                    </Typography>
                </ListItem>
                <ListItem>
                    <Typography variant="body1" align="justify">
                        - вносити застереження стосовно обмеження права на Обробку своїх Персональних Даних під час надання Згоди;
                    </Typography>
                </ListItem>
                <ListItem>
                    <Typography variant="body1" align="justify">
                        - відкликати Згоду на Обробку Персональних Даних;
                    </Typography>
                </ListItem>
                <ListItem>
                    <Typography variant="body1" align="justify">
                        - знати механізм автоматичної Обробки Персональних Даних;
                    </Typography>
                </ListItem>
                <ListItem>
                    <Typography variant="body1" align="justify">
                        - на захист від автоматизованого рішення, яке може мати для Вас правові наслідки.
                    </Typography>
                </ListItem>
            </List>
            <Typography variant="body1" align="justify" gutterBottom>
                З метою відкликати Згоду на Обробку Персональних Даних, скористатися своїм правом доступу до Ваших Персональних Даних, предʼявити вимогу про видалення Персональних Даних та реалізувати інші свої права, Ви можете надіслати відповідний запит на Нашу електронну адресу: klaptuk.peremogu@gmail.com. Ми намагатимемося відповісти Вам якомога швидше, але не довше ніж упродовж 1 (одного) календарного місяця.
            </Typography>
            <Typography variant="body1" align="justify" gutterBottom>
                Зверніть увагу, що, перш ніж надати інформацію згідно з Вашим запитом, Ми можемо попросити Вас повідомити додаткові Дані для підтвердження Вашої особи.
            </Typography>
            <Typography variant="body1" align="justify" gutterBottom>
                9. Посилання на інші сайти.
            </Typography>
            <Typography variant="body1" align="justify" gutterBottom>
                Ми можемо розміщувати активні посилання на сайти, які не обслуговуються Компанією. Якщо Ви відвідуєте сайти за такими посиланнями, Вам необхідно переглянути їхні політики збору та Обробки Даних та принципи роботи. Ми не несемо відповідальності за політики та практики інших компаній, і будь-яка інформація, яку Ви надаєте таким компаніям, підпорядковується їхнім політикам збору й Обробки Даних.
            </Typography>
            <Typography variant="body1" align="justify" gutterBottom>
                10. Вирішення спорів.
            </Typography>
            <Typography variant="body1" align="justify" gutterBottom>
                До моменту звернення до суду з позовом у спорах, що виникають із відносин між Користувачем і Нами, обов'язковим є пред'явлення претензії (письмової пропозиції про добровільне врегулювання спору). Отримувач претензії впродовж 30 (тридцяти) календарних днів з дня її отримання повинен письмово повідомити заявника про результати розгляду претензії.
            </Typography>
            <Typography variant="body1" align="justify" gutterBottom>
                У разі недосягнення компромісу суперечка може бути передана на розгляд до компетентного суду України. До цієї Політики та відносин між Користувачем і Компанією застосовується чинне законодавство України.
            </Typography>
            <Typography variant="body1" align="justify" gutterBottom>
                11. Оновлення Політики.
            </Typography>
            <Typography variant="body1" align="justify" gutterBottom>
                Ми маємо право в будь-який час і за будь-яких підстав вносити зміни до цієї Політики без попереднього сповіщення Користувачів, розмістивши нову редакцію Політики за вищезазначеним посиланням.  Датою вступу в силу нової редакції Політики вважається дата опублікування Політики на Платформі. Компанія не зобов'язана повідомляти Користувачів про внесені зміни. Ми наполегливо рекомендуємо Вам час від часу перевіряти Політику й дату внесення останніх змін, аби залишатися в курсі останніх змін.
            </Typography>
            <Typography variant="body1" align="justify" gutterBottom>
                У разі незгоди з будь-якими змінами, внесеними до Політики, Ви повинні припинити користуватися Платформою, а також маєте право вимагати видалення Ваших Персональних Даних.
            </Typography>
            <Typography variant="body1" align="justify" gutterBottom>
                12. Контактна інформація.
            </Typography>
            <Typography variant="body1" align="justify" gutterBottom>
                Якщо у Вас є зауваження, сумніви чи запитання щодо цієї Політики, будь ласка, зв'яжіться з Нами за електронною адресою rusgoodbuy@gmail.com.
            </Typography>
            <Typography variant="body1" align="justify" gutterBottom>
                Ми намагатимемося відповісти Вам якомога швидше, але не довше ніж упродовж 1 (одного) календарного місяця.
            </Typography>
            <Typography variant="body1" align="justify" gutterBottom>
                13. Додаткова інформація для резидентів штату Каліфорнія, США.
            </Typography>
            <Typography variant="body1" align="justify" gutterBottom>
                Цей розділ стосується тільки резидентів штату Каліфорнія, США. Відповідно до CCPA та CalOPPA, нижче наведено короткий опис визначених CCPA та CalOPPA категорій Персональних Даних (відповідно до умов цього розділу, визначені як Особиста інформація), які Ми збираємо, причин, з яких Ми збираємо Вашу Особисту інформацію, джерел, з яких Ми отримуємо Вашу Особисту інформацію, та третіх осіб, яким Ми можемо передавати Вашу Особисту інформацію.
            </Typography>
            <Typography variant="body1" align="justify" gutterBottom>
                Зверніть увагу, що Компанія не “продає” й не “продаватиме” Вашу Особисту інформацію.
            </Typography>
            <Typography variant="body1" align="justify" gutterBottom>
                13.1. Права на конфіденційність у штаті Каліфорнія, США.
            </Typography>
            <Typography variant="body1" align="justify" gutterBottom>
                Якщо Ви є резидентом штату Каліфорнія, США, Ви маєте відповідні права щодо Вашої Особистої інформації, проте на них поширюються певні винятки. Наприклад, Ми не можемо розголошувати конкретні фрагменти Особистої інформації, якщо розголошення може створити суттєвий, явний та нерозумний ризик для безпеки Вашої Особистої інформації або безпеки Наших мережевих систем.
            </Typography>
            <Typography variant="body1" align="justify" gutterBottom>
                13.2. Доступ до конкретних Даних і права на перенесення Даних.
            </Typography>
            <Typography variant="body1" align="justify" gutterBottom>
                За певних винятків, якщо Ви є резидентом штату Каліфорнія, США, Ви маєте право вимагати отримання копії Особистої інформації, яку Ми збирали про Вас упродовж 12 місяців до Вашого запиту. Отримавши Ваш запит і встановивши Вашу особу, Ми повідомимо Вам:
            </Typography>
            <Typography variant="body1" align="justify" gutterBottom>
                – категорії Особистої інформації, яку Ми зібрали про Вас;
            </Typography>
            <Typography variant="body1" align="justify" gutterBottom>
                – Нашу ділову або комерційну мету для збору такої Особистої інформації;
            </Typography>
            <Typography variant="body1" align="justify" gutterBottom>
                – категорії третіх осіб, з якими Ми ділимося цією Особистою інформацією.
            </Typography>
            <Typography variant="body1" align="justify" gutterBottom>
                Ви також маєте право попросити перенести Вашу Особисту інформацію до іншого Контролера Особистої інформації з метою її подальшої Обробки таким Контролером.
            </Typography>
            <Typography variant="body1" align="justify" gutterBottom>
                13.3. Право на запит на видалення.
            </Typography>
            <Typography variant="body1" align="justify" gutterBottom>
                Ви маєте право вимагати від нас видалення будь-якої Вашої Особистої інформації, яку Ми зібрали про Вас і зберегли.
            </Typography>
            <Typography variant="body1" align="justify" gutterBottom>
                13.4. Недискримінація.
            </Typography>
            <Typography variant="body1" align="justify" gutterBottom>
                Ми не дискримінуватимемо Вас за здійснення Ваших прав, пов’язаних з Обробкою Вашої Особистої інформації, а також Вашого права на відмову від отримання Наших Послуг надалі, чи за відмову від подальшого отримання маркетингових, інформаційних / рекламних матеріалів від Нас.
            </Typography>
            <Typography variant="body1" align="justify" gutterBottom>
                13.5. Відмова від продажу.
            </Typography>
            <Typography variant="body1" align="justify" gutterBottom>
                Ми не “продаємо” та не “продаватимемо” Вашу Особисту інформацію, але Ми усвідомлюємо, що CCPA визначає “Особисту інформацію” таким чином, що надання доступу до ідентифікаторів, пов’язаних з Вами, може вважатися “продажем”. У разі, якщо ці ідентифікатори й висновки щодо Користувачів Платформи можуть бути визначені як продаж відповідно до CCPA, будь ласка, скористайтеся налаштуваннями “Не продавати мою Особисту інформацію” на головній сторінці Платформи. Після отримання Вашого запиту через налаштування “Не продавати мою Особисту інформацію” Компанія вносить до своєї бази Особистої інформації / Користувачів Платформи відповідний запис, який свідчить про усвідомлення Компанією неможливості в подальшому продажу Вашої Особистої інформації.
            </Typography>
            <Typography variant="body1" align="justify" gutterBottom>
                14. Додаткова інформація для резидентів Канади.
            </Typography>
            <Typography variant="body1" align="justify" gutterBottom>
                Цей розділ стосується лише резидентів Канади. Компанія Обробляє Персональні Дані відповідно до Закону “Про захист персональної інформації та електронних документів” (надалі - “PIPEDA”).
            </Typography>
            <Typography variant="body1" align="justify" gutterBottom>
                Окрім прав, які перелічені в розділі 8 цієї Політики, Ви також маєте право подавати запит до Нас з метою дізнатися, які саме Персональні Дані Ми збираємо, право відкликати свою Згоду на Обробку Персональних Даних, право оскаржувати дотримання Нами положень PIPEDA до Офісу уповноваженого з питань конфіденційності Канади (OPC).
            </Typography>
            <Typography variant="body1" align="justify" gutterBottom>
                З метою подання запиту щодо того, які саме Ваші Персональні Дані Ми Обробляємо, зв’яжіться з Нами за адресою: klaptuk.peremogu@gmail.com. У разі отримання Вашого запиту на доступ до Персональних Даних, Ми будемо намагатись відповісти якомога швидше, але не пізніше ніж упродовж 30 (тридцяти) календарних днів з дня отримання запиту.
            </Typography>
            <Typography variant="body1" align="justify" gutterBottom>
                Ми маємо право продовжити 30-денний термін на надання відповіді на запит ще на 30 (тридцять) днів у разі:
            </Typography>
                <List>
                    <ListItem>
                        <Typography variant="body1" align="justify">
                            - надання відповіді в межах початкових 30 (тридцяти) днів може вважатись безпідставним втручанням у Нашу діяльність;
                        </Typography>
                    </ListItem>
                    <ListItem>
                        <Typography variant="body1" align="justify">
                            - Нам потрібен додатковий час для консультацій;
                        </Typography>
                    </ListItem>
                    <ListItem>
                        <Typography variant="body1" align="justify">
                            - Нам потрібен додатковий час для перетворення Персональних Даних у зручний для ознайомлення формат.
                        </Typography>
                    </ListItem>
                </List>
            <Typography variant="body1" align="justify" gutterBottom>
                Ви маєте право у разі ненадання Нами відповіді на Ваш запит у межах перших 30 (тридцяти) днів подати скаргу до Офісу уповноваженого з питань конфіденційності Канади (OPC).
            </Typography>
            <Typography variant="body1" align="justify" gutterBottom>
                У випадку витоку Ваших Персональних Даних Ми своєчасно повідомимо Вас про такий випадок та про ступінь загрози Вашим Персональним Даним. Відповідно до PIPEDA Ми одразу повідомимо Офісу уповноваженого з питань конфіденційності Канади (OPC) та будемо вести облік витоку, що стався.
            </Typography>
            <Typography variant="body1" align="justify" gutterBottom>
                Ми можемо розкривати Персональні Дані стороннім постачальникам послуг і пов'язаним з ними корпораціям, розташованим за межами Канади, для цілей, зазначених у Політиці. Політика вимагає, щоб Компанія ділилася Вашими Персональними Даними за кордоном таким чином, щоб це вимагало найсуворіших стандартів конфіденційності та безпеки, під час транспортування Персональних Даних за кордоном. Звертаємо увагу, що при передачі Персональних Даних за кордон до них можуть отримати доступ суди, правоохоронні органи та органи національної безпеки. Перед розголошенням Персональних Даних Ми оцінимо можливі ризики, які можуть негативно вплинути за захист Персональних Даних. Ми забезпечуємо належний рівень цілісності та захисту Персональних Даних при передачі третім особам, який вимагається положеннями PIPEDA, шляхом укладання з такими компаніями договорів, якими передбачається обмеження використання Персональних Даних третьою стороною виключно цілями, які передбачаються в договорі.
            </Typography>
        </DialogContent>
    </Dialog>
}

export default PrivatePolicyModal;
