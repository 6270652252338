import {usePlot} from "../../context/PlotContext";
import {GeoJSON, Pane, Popup, useMapEvent, useMapEvents} from "react-leaflet";
import React, {useMemo, useState} from "react";
import {defaultZoom, showZoom} from "../../App";
import {useTranslation} from "react-i18next";
import {isEmpty} from "lodash";

interface UpdatableCommentProps {
    text: string,
    translate: boolean, //переводить ли текст, нужно для сообщения об ожидании оплаты или при отсутствии сообщения
    pane: string //имя родителя для выставления ему z-index, чтобы попап не был под другими участками
}

//Для перевода описания участка без перезагрузки страницы
const UpdatablePopup = ({text, translate, pane}: UpdatableCommentProps) => {
    //чтобы попап не перекрывался другими участками
    useMapEvents({
        "popupopen": (it: any) => {
            if (it.popup.options.pane !== pane) {
                return
            }
            //@ts-ignore
            document.querySelector(`div[class*='${pane}']`).setAttribute('style', 'z-index: 8002')
        },
        "popupclose": (it: any) => {
            //@ts-ignore
            document.querySelector(`div[class*='${it.popup.options.pane}']`).setAttribute('style', 'z-index: 8000')
        }
    })
    const {t} = useTranslation()
    if (!translate) {
        return <Popup>{text}</Popup>
    }
    return <Popup>{t(text)}</Popup>
}

//Проданные участки
const Sold = () => {
    const {sold} = usePlot();

    const [zoom, setZoom] = useState(defaultZoom)

    // @ts-ignore
    useMapEvent('zoom', e => setZoom(e.target.getZoom()))

    const grids = useMemo(() => {
        return sold.map(it => {
            /** @param it {Grid} */
            const style = {
                color: it.options.active ? it.options.color as string : 'red', //Он красный если еще не оплачен
                weight: 2
            }
            it.createGrid({limit: false})
            let comment: string = isEmpty(it.options.comment) ? 'message_sold' : it.options.comment as string
            let active: boolean = it.options.active as boolean

            if (!active) {
                comment = 'waiting_payment'
            }
            const translate = !active || isEmpty(it.options.comment)

            return <Pane style={{zIndex: 8000}} name={it.key} key={it.key}>
                {/** @ts-ignore */}
                <GeoJSON style={style} data={it.grid}>
                    <UpdatablePopup text={comment} translate={translate} pane={it.key}/>
                </GeoJSON>
            </Pane>
        })
    }, [sold])

    if (zoom < showZoom) {
        return null;
    }

    return grids
}
export default Sold
