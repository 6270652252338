import React, {useRef, useState} from "react";
import {
    Box,
    Fab,
    Divider,
    Link,
    ListItemIcon,
    ListItemText,
    Menu as MenuMaterial,
    MenuItem,
    MenuList,
    MenuProps,
    ToggleButton,
    ToggleButtonGroup,
} from "@mui/material";
import {
    Instagram,
    Language,
    Menu as MenuIcon,
    QuizOutlined,
    Slideshow,
    VolunteerActivismOutlined
} from "@mui/icons-material"
import {styled} from "@mui/material/styles";
import {useTranslation} from "react-i18next";
import Greeting from "../greeting/Greeting";
import Info from "../feedback/Feedback";
import Donate from "../donate/Donate";
import PrivatePolicyModal from "../private-policy/PrivatePolicy";
import PublicOfferModal from "../public-offer/PublicOffer";
import i18n from "../../util/i18n";
import borders from '../../util/styles/borders';
import boxShadows from '../../util/styles/boxShadows'

const StyledMenu = styled((props: MenuProps) => (
    <MenuMaterial
        {...props}
    />
))(() => ({
    '& .MuiPaper-root': {
        borderRadius: borders.borderRadius.xl,
        width: '200px',
        marginTop: '7px',
        '@media (min-width: 600px)': {
            marginTop: '3px',
        }
    }
}));

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(() => ({
    '& .MuiToggleButtonGroup-grouped': {
        height: '30px',
        border: 0,
        borderRadius: 0,
        '&:first-of-type': {
            borderRight: '1px solid'
        },
        '&.Mui-selected': {
            backgroundColor: 'transparent'
        },
    },
}));

const Menu = () => {
    const [open, setOpen] = useState<boolean>(false);
    const [greetingOpen, setGreetingOpen] = useState<boolean>(localStorage.getItem('greeting') !== '1');
    const [infoOpen, setInfoOpen] = useState<boolean>(false);
    const [donateOpen, setDonateOpen] = useState<boolean>(false);
    const [privatePolicyOpen, setPrivatePolicyOpen] = useState<boolean>(false);
    const [publicOfferOpen, setPublicOfferOpen] = useState<boolean>(false);

    const {t} = useTranslation();

    const anchor = useRef<HTMLButtonElement>(null);

    return <>
        <Fab key='btn' ref={anchor} onClick={() => setOpen(!open)}
             sx={{
                 minWidth: '44px',
                 width: '44px',
                 height: '44px',
                 borderRadius: borders.borderRadius.lg,
                 boxShadow: boxShadows.colored.white,
                 backgroundColor: '#ffffff'
             }}>
            <MenuIcon/>
        </Fab>
        <StyledMenu
            onClose={() => setOpen(false)}
            key='menu'
            open={open}
            anchorEl={anchor.current}
        >
            <MenuList>
                <MenuItem onClick={() => setGreetingOpen(true)}>
                    <ListItemIcon>
                        <Slideshow/>
                    </ListItemIcon>
                    <ListItemText>
                        {t('about_project')}
                    </ListItemText>
                </MenuItem>
                <MenuItem onClick={() => setInfoOpen(true)}>
                    <ListItemIcon>
                        <QuizOutlined/>
                    </ListItemIcon>
                    <ListItemText>
                        {t('feedback')}
                    </ListItemText>
                </MenuItem>
                <MenuItem
                    component='a'
                    target="_blank"
                    rel="noreferrer"
                    href='https://instagram.com/klaptuk.peremogu?igshid=ZDdkNTZiNTM='
                >
                    <ListItemIcon>
                        <Instagram/>
                    </ListItemIcon>
                    <ListItemText>
                        {t('insta')}
                    </ListItemText>
                </MenuItem>
                <MenuItem onClick={() => setDonateOpen(true)}>
                    <ListItemIcon>
                        <VolunteerActivismOutlined/>
                    </ListItemIcon>
                    <ListItemText>
                        {t('donate')}
                    </ListItemText>
                </MenuItem>
                <Divider/>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    padding: '0 16px 5px'
                }}>
                    <ListItemIcon sx={{
                        minWidth: '30px'
                    }}>
                        <Language/>
                    </ListItemIcon>
                    <ListItemText>
                        <StyledToggleButtonGroup
                            value={i18n.language}
                            defaultValue='uk'
                            exclusive
                            size="small"
                            onChange={(_, value) => i18n.changeLanguage(value)}
                            aria-label="lng"
                        >
                            <ToggleButton value="uk" aria-label="UA">
                                {t('lang_ukr')}
                            </ToggleButton>
                            <ToggleButton value="en" aria-label="EN">
                                {t('lang_eng')}
                            </ToggleButton>
                        </StyledToggleButtonGroup>
                    </ListItemText>
                </Box>
                <Divider/>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '10px 18px 3px'
                }}>
                    <Link
                        onClick={() => setPrivatePolicyOpen(true)}
                        variant="body2"
                        underline="hover"
                        color="inherit"
                        sx={{
                            cursor: 'pointer',
                            fontSize: '0.75rem'
                        }}
                    >
                        {t('private_policy')}
                    </Link>
                </Box>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '0 18px'
                }}>
                    <Link
                        onClick={() => setPublicOfferOpen(true)}
                        variant="body2"
                        underline="hover"
                        color="inherit"
                        sx={{
                            cursor: 'pointer',
                            fontSize: '0.75rem'
                        }}
                    >
                        {t('public_offer')}
                    </Link>
                </Box>
            </MenuList>
        </StyledMenu>
        <Info key='info' open={infoOpen} close={() => setInfoOpen(false)}/>
        <Greeting
            key='greeting'
            open={greetingOpen}
            firstTime={localStorage.getItem('greeting') !== '1'}
            close={() => {
                setGreetingOpen(false)
                localStorage.setItem('greeting', '1')
            }}
        />
        <Donate key='donate' open={donateOpen} close={() => setDonateOpen(false)} />
        <PrivatePolicyModal key='private-policy' open={privatePolicyOpen} close={() => setPrivatePolicyOpen(false)} />
        <PublicOfferModal key='public-offer' open={publicOfferOpen} close={() => setPublicOfferOpen(false)}/>
    </>
}

export default Menu
