import {MapContainer, ZoomControl} from "react-leaflet";
import "leaflet/dist/leaflet.css"
import All from "./map/empty/All";

import './App.css';

// import ShowData from "./debug/ShowData";
import Selection from "./map/selection/Selection";
import {Toaster} from "react-hot-toast";
import TileLayerSwitch from "./map/tile/TileLayerSwitch";
import OrderModal from "./main-bar/order/OrderModal";
import {Loader} from "./context/Loader";
import Sold from "./map/sold/Sold";
import {GoogleReCaptchaProvider} from "react-google-recaptcha-v3";
import PlotContextProvider from "./context/PlotContext";
// @ts-ignore
import MainBar from "./main-bar/MainBar";
import React, {useMemo} from 'react';
import {Backdrop, CircularProgress} from "@mui/material";
import Feedback from "./page/Feedback";

const defaultZoom = 15
const showZoom = 15
export {defaultZoom, showZoom}

function App() {
    //Ссылка на конкретную область с конкретным зумом
    const {zoom, center} = useMemo(() => {
        const params = new URLSearchParams(document.location.search)
        const zoom = parseInt(params.get('s') ?? defaultZoom.toString())
        let center = [55.699, 37.6492]
        if (params.has('c')) {
            const split = params.get('c')!.split('-').map(it => parseFloat(it))
            if (split.length === 2 && !split.some(it => isNaN(it))) {
                center = split
            }
        }
        return {zoom, center}
    }, []);
    if (window.location.search.includes('?f=true&key=')) {
        // @ts-ignore
        return <Feedback/>
    }

    return [
        <Toaster key='toaster'/>, /* Уведомления */
        <MapContainer key='map'
            // @ts-ignore
                      zoomControl={false}
                      center={center}
                      zoom={zoom}
                      style={{height: '100vh'}}
        >
            <React.Suspense fallback={<Backdrop style={{zIndex: 9000}} open={true}><CircularProgress/></Backdrop>}>
                {/* @ts-ignore */}
                <Loader>
                    <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_V3_KEY!!}> {/* Капча */}
                        {/* Купленные и выделенные клетки */}
                        <PlotContextProvider>
                            <MainBar/> {/* Header c Меню, формой обратной связи и общей информацией, приветственная информация. */}
                            {/* @ts-ignore */}
                            <Selection/> {/* Селектор участков */}
                            {/* @ts-ignore */}
                            <Sold/> {/* Проданные участки */}
                            {/* @ts-ignore */}
                            <OrderModal/> {/* Модалка для оплаты */}
                        </PlotContextProvider>
                    </GoogleReCaptchaProvider>
                </Loader>
                <All/> {/* Все клетки */}
                {/*<ShowData/> /!* Дебаг TODO remove *!/*/}
            </React.Suspense>
            <TileLayerSwitch/> {/* Переключение между видом карты и местности */}
            <ZoomControl position="bottomleft"/>
        </MapContainer>
    ]
}

export default App;
