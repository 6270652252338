import React, {useEffect, useMemo, useState} from "react";
import {GeoJSON, useMapEvents} from "react-leaflet";
import {Grid, gridBetween, LatLng, MAX_PLOT_SIZE, Point} from "../../util/gridUtil";
import "leaflet/dist/images/marker-shadow.png";
import toast from "react-hot-toast";
import {usePlot} from "../../context/PlotContext";
import {defaultZoom, showZoom} from "../../App";
import Decimal from "decimal.js";
import {useTranslation} from "react-i18next";
import {booleanPointInPolygon, MultiPolygon} from "@turf/turf";
import russia from "../boundaries/russia";

const gridStyle = {
    weight: 0.6,
    color: 'yellow',
    zIndex: 3
};
/**
 * Селектор клеток
 */
const Selection = () => {
    const [zoom, setZoom] = useState<number>(defaultZoom);

    const [firstPoint, setFirstPoint] = useState<LatLng | null>(null);
    const [secondPoint, setSecondPoint] = useState<LatLng | null>(null);
    const {t} = useTranslation()

    useMapEvents({
        // @ts-ignore
        'click': e => {
            //отменить клик не по карте
            const keys = Object.keys(e.originalEvent.srcElement)
            if (keys.length !== 1 || keys[0] !== '_leaflet_id') {
                return;
            }
            const point: LatLng = e.latlng
            if (!booleanPointInPolygon([point.lng, point.lat], russia.features[0].geometry as MultiPolygon)) {
                toast.error(t('selection.not_inside'))
                return;
            }

            if (firstPoint === null) {
                setFirstPoint(point)
                return;
            }
            const first = new Point(new Decimal(firstPoint.lng), new Decimal(firstPoint.lat))
            const second = new Point(new Decimal(point.lng), new Decimal(point.lat))
            if (first.equals(second)) {
                if (secondPoint !== null) {
                    setSecondPoint(null)
                }
                return;
            }
            if (secondPoint !== null) {
                setFirstPoint(null)
                setSecondPoint(null)
                setSelection(null)
                return;
            }
            setSecondPoint(point)
        },
        // @ts-ignore
        'zoom': e => setZoom(e.target.getZoom())
    });

    const { setSelection } = usePlot();

    const grid = useMemo(() => {
        //не показывать если зума не хватает
        if ((firstPoint === null && secondPoint == null) || zoom < showZoom) {
            return Grid.EMPTY
        }
        //Сетка из 1 квадрата
        if (firstPoint !== null && secondPoint === null) {
            return Point.fromLatLng(firstPoint).singleGrid();
        }
        const first = Point.fromLatLng(firstPoint!!)
        const second = Point.fromLatLng(secondPoint!!)

        //null значит сетка больше максимального размера
        const newGrid = gridBetween(first, second, {accurate: true})
        if (newGrid === null) {
            toast.error(t('selection.too_big', {max: MAX_PLOT_SIZE}))
            setFirstPoint(null)
            setSecondPoint(null)
            return Grid.EMPTY
        }
        return newGrid
    }, [firstPoint, secondPoint, zoom]);

    useEffect(() => {
        setSelection(grid)
    }, [grid])

    const resetSelection = (e: KeyboardEvent) => {
        if (e.key !== 'Escape' || e.keyCode !== 27) {
            return
        }
        setFirstPoint(null)
        setSecondPoint(null)
    }

    useEffect(() => {
        document.removeEventListener('keyup', resetSelection)
        document.addEventListener('keyup', resetSelection)
    }, [setSelection])

    // @ts-ignore
    return <GeoJSON key={`select-${grid.key}`} data={grid.grid} style={gridStyle}/>
}
export default Selection
