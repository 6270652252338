import * as React from 'react';
import {useState} from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import {styled} from '@mui/material/styles';

import borders from '../../util/styles/borders';
import boxShadows from '../../util/styles/boxShadows'
import typographyStyles from '../../util/styles/typography';
import linerGradient from '../../util/styles/functions/linerGradient';
import colors from '../../util/styles/colors';
import SvgIcon from "@mui/material/SvgIcon";
import {usePlot} from "../../context/PlotContext";
import toast from "react-hot-toast";
import {calculatePlotSize} from "../../util/gridUtil";
import {SQUARE_PRICE} from "../../util/util";
import {useTranslation} from "react-i18next";

const infoBoxTitle = {
    ...typographyStyles.baseDisplayProperties,
    color: colors.text.main,
    fontSize: typographyStyles.baseProperties.fontSizeSM,
    textAlign: 'right'
};
const infoBoxCount = {
    ...typographyStyles.baseDisplayProperties,
    fontSize: typographyStyles.baseProperties.fontSize2XL,
    fontWeight: typographyStyles.baseProperties.fontWeightBold,
    textAlign: 'right'
};
const total = {
    ...typographyStyles.baseDisplayProperties,
    fontSize: typographyStyles.baseProperties.fontSizeSM,
};
const BuyButton = styled(Button)({
    background: linerGradient(colors.gradients.info.main, colors.gradients.info.state),
    color: colors.white.main,
    border: `${borders.borderWidth['1']} solid ${colors.gradients.info.state}`,
    borderRadius: borders.borderRadius.lg,
    boxShadow: boxShadows.colored.button,
    '&:hover': {
        background: linerGradient(colors.gradients.infoHover.main, colors.gradients.infoHover.state),
        border: `${borders.borderWidth['1']} solid ${colors.gradients.infoHover.state}`,
    }
});

const ToggleButton = styled(IconButton)({
    minWidth: '44px',
    width: '44px',
    height: '44px',
    marginRight: '20px',
    background: linerGradient(colors.gradients.primary.main, colors.gradients.primary.state),
    border: `${borders.borderWidth['1']} solid ${colors.gradients.primary.state}`,
    color: colors.white.main,
    borderRadius: borders.borderRadius.lg,
    boxShadow: boxShadows.colored.red,
    '&:hover': {
        background: linerGradient(colors.gradients.primaryHover.main, colors.gradients.primaryHover.state),
        border: `${borders.borderWidth['1']} solid ${colors.gradients.primaryHover.state}`,
    }
});

const InfoBox = () => {
    const [show, setShow] = useState('');

    const {selection, setOrderOpen, sold} = usePlot();
    const {t} = useTranslation();

    const count = selection === null ? 0 : calculatePlotSize(selection, sold);

    const toggleCard = (prevState: string): string => {
        if (prevState === 'hide') {
            return 'show';
        }

        if (prevState === '' && !count) {
            return 'show';
        }

        return 'hide';
    }

    const openOrderModal = () => {
        if (isNaN(count) || count < 1) {
            toast.error(t('selection.select_first'))
            return
        }
        if (count !== selection?.grid?.features?.length) {
            toast.error(t('selection.intersection'))
            return;
        }
        setOrderOpen(true)
    }

    if (!count && show === 'hide') {
        setShow('');
    }

    return (
        <>
            <ToggleButton
                key='btn'
                variant="outlined"
                onClick={() => setShow(toggleCard)}
            >
                <SvgIcon width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M16.7163 15.3603L14.4821 10.2441C14.4144 10.0961 14.262 9.99738 14.0928
                         9.99738H10.3691V11.7905C10.3691 12.6788 9.62429 13.4192 8.69335 13.4192C7.7794
                         13.4192 7.01764 12.6953 7.01764 11.7905V9.99738H3.2939C3.12459 9.99738 2.97228
                         10.0961 2.90463 10.2441L0.704211 15.3603C0.653442 15.4755 0.670325 15.6236 0.738096
                         15.7387C0.822751 15.8539 0.941173 15.9196 1.0936 15.9196L16.3271 15.9197C16.4625
                         15.9197 16.5979 15.854 16.6656 15.7388C16.7671 15.6236 16.7671 15.4755 16.7163
                         15.3603L16.7163 15.3603Z" fill="white"/>
                    <path d="M4.52805 7.17047H8.01802V11.8366C8.01802 12.2144 8.33698 12.5355 8.71222 12.5355C9.08747
                         12.5355 9.40643 12.2144 9.40643 11.8366V7.17047H12.8964C13.1591 7.17047 13.3655 6.96266 13.3655
                         6.69819V1.16308C13.3655 0.898606 13.1591 0.690796 12.8964 0.690796H4.52798C4.2653 0.690796 4.0589
                         0.898606 4.0589 1.16308V6.69819C4.0589 6.9628 4.2653 7.17061 4.52798 7.17061L4.52805 7.17047ZM6.36682
                         2.40989H11.0389C11.3015 2.40989 11.508 2.6177 11.508 2.88217C11.508 3.14664 11.3015 3.35445 11.0389
                         3.35445H6.36682C6.10414 3.35445 5.89773 3.14664 5.89773 2.88217C5.91645 2.6177 6.12285 2.40989 6.36682
                         2.40989ZM6.36682 4.52581H11.0389C11.3015 4.52581 11.508 4.73362 11.508 4.99809C11.508 5.26256 11.3015
                         5.47037 11.0389 5.47037H6.36682C6.10414 5.47037 5.89773 5.26256 5.89773 4.99809C5.91645 4.73348 6.12285
                         4.52581 6.36682 4.52581Z" fill="white"/>
                </SvgIcon>
            </ToggleButton>
            <Card hidden={show === 'hide' || (show === '' && !count)} key='card' sx={{
                position: 'fixed',
                top: '57px',
                left: '16px',
                minWidth: 242,
                borderRadius: borders.borderRadius.xl
            }}>
                <CardContent>
                    <Typography sx={infoBoxTitle}
                                gutterBottom>
                        {t('selection.amount')}:
                    </Typography>
                    <Typography sx={infoBoxCount}>
                        {count || 0}
                    </Typography>
                </CardContent>
                <Divider/>
                <CardActions sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    padding: '15px'
                }}>
                    <Typography sx={total}>
                        <b>{t('selection.price')}</b>
                        {' '}
                        {(count || 0) * SQUARE_PRICE}
                        {' '}
                        {t('uah')}
                    </Typography>
                    <BuyButton onClick={openOrderModal} variant="outlined">
                        <Typography sx={{...typographyStyles.button}}>
                            {t('selection.buy')}
                        </Typography>
                    </BuyButton>
                </CardActions>
            </Card>
        </>
    );
}
export default InfoBox;
