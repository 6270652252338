import React from "react";
import { useTranslation } from "react-i18next";
import { Dialog, DialogContent, DialogTitle, IconButton, Typography } from "@mui/material";
import { Close, ContentCopy } from "@mui/icons-material";

interface DonateProps {
    open: boolean,
    close: () => void
}

const DonateModal = ({open, close}: DonateProps) => {
    const {t} = useTranslation();
    const cardNumber = '4441111153569888';

    return <Dialog
        fullWidth
        open={open}
        onClose={close}
    >
        <DialogTitle>
            {t('donate')}
            <IconButton
                onClick={close}
                size="small"
                sx={{
                    position: 'absolute',
                    right: '10px',
                    top: '10px'
                }}
            >
                <Close/>
            </IconButton>
        </DialogTitle>
        <DialogContent>
            <Typography style={{
                marginBottom: '10px'
            }}>
                {t('donate_text')}
            </Typography>
            <Typography variant="h6">
                {cardNumber}
                <IconButton
                    onClick={() => {navigator.clipboard.writeText(cardNumber)}}
                    size="small"
                >
                    <ContentCopy/>
                </IconButton>
            </Typography>
        </DialogContent>
    </Dialog>
}

export default DonateModal;
