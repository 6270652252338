import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from "@mui/material";
import React from "react";
import {useTranslation} from "react-i18next";

type Props = {
    email: string
    close: () => void
    open: boolean,
    form: string
}

const OrderSentModal = ({email, close, open, form}: Props) => {
    const {t} = useTranslation()
    return <Dialog open={open}>
        <DialogTitle>
            {t('order.payment')}
        </DialogTitle>
        <DialogContent>
            <Typography textAlign='center'>
                {t('order.time')}
            </Typography>

            <div style={{textAlign: 'center'}} dangerouslySetInnerHTML={{__html: form}}/>

            <Typography textAlign='center'>
                {t('order.also_sent')}
                {' '}
                {email}
            </Typography>
        </DialogContent>
        <DialogActions>
            <Button onClick={close}>{t('close')}</Button>
        </DialogActions>
    </Dialog>
}
export default OrderSentModal
