import i18n from "./i18n";

export const backendAddress = process.env.REACT_APP_BACKEND_ADDRESS
export const apiAddress = backendAddress + '/api'

export const CREATE_ORDER = apiAddress + '/order' //POST
export const PLOTS_GET_ALL = apiAddress + '/plot' //GET
export const FEEDBACK = apiAddress + '/feedback' //POST, GET

export const SQUARE_PRICE = 100; //UAH

/**
 * получить язык в нужном формате
 */
export const getCurrentLanguage = (): string => {
    const lng = i18n.language
    if (lng.includes('en')) {
        return 'en'
    }
    return 'ua'
}

export const emailValid = (email: string) => email.toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)

export const getErrorMessage = (obj: any): string => {
    if ('request' in obj) {
        if (!obj.request || !obj.request.response) {
            return 'Request error'
        }
    }
    if ('response' in obj) {
        obj = obj.response
        if ('data' in obj) {
            obj = obj.data
        }
    }
    if ('message' in obj && obj.message.length > 0) {
        return obj.message
    }
    if (obj.request && obj.request.response && 'message' in obj.request.response && obj.request.response.message.length > 0) {
        return obj.request.response.message
    }
    if ('error' in obj) {
        const err = obj.error
        if (typeof err === 'object' && 'message' in err) {
            return err.message
        }
        return err
    }
    if ('exception' in obj) {
        return obj.exception
    }
    return obj.toString()
}