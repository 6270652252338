import * as React from 'react';
import {AppBar, SvgIcon, Toolbar} from '@mui/material';
import {useTranslation} from "react-i18next";
import Infobox from './info/Info';
import Menu from './menu/Menu';
import zIndexes from '../util/styles/zIndexes';
import Typography from "@mui/material/Typography";

const MainBar = () => {
    const {t} = useTranslation();
    return (
        <AppBar
            position="fixed"
            color="primary"
            sx={{zIndex: zIndexes.values.mainBar}}
        >
            <Toolbar>
                <Infobox/>
                <SvgIcon
                    width="36"
                    height="59"
                    viewBox="0 0 36 59"
                    fill="#ffffff"
                    xmlns="http://www.w3.org/2000/svg"
                    sx={{width: '1.7em', height: '1.7em'}}
                >
                    <path
                        d="M9.49857 43.8611C9.49857 43.928 9.50083 43.9945 9.50179 44.0612H12.6446C12.6421 43.9779 12.6402
                        43.8942 12.6392 43.8106C12.638 43.7418 12.6371 43.673 12.637 43.6043C12.6346 42.5655 12.8747
                        41.5564 12.9033 41.1632C13.7284 35.1669 17.6626 32.6006 17.7465 32.5466C18.0179 32.7799 21.8704
                        35.8929 22.6033 41.2429C22.6309 41.6355 22.8526 42.6532 22.8656 43.6927C22.8675 43.7616 22.8683
                        43.8307 22.8681 43.8999C22.8678 43.9537 22.8671 44.0074 22.8662 44.0612H26.0017C26.0029 43.9945
                        26.0049 43.9281 26.0049 43.8611C26.0049 38.5869 23.754 34.8439 21.6032 32.4704H21.6645C19.8259
                        28.6136 19.4947 24.299 19.684 20.0165C19.865 15.9235 20.0003 10.6419 20.1553 6.54795C20.2398
                        4.31118 19.7009 2.24057 18.1776 0.434275C18.0852 0.324846 17.7973 0.0502045 17.7185 0C17.7185
                        0 17.3521 0.32888 17.2601 0.437953C15.7367 2.24437 15.1979 4.31486 15.2825 6.55152C15.4373
                        10.6454 15.5727 15.9269 15.7538 20.0201C15.9421 24.2818 15.7434 28.6446 13.8507 32.4454C11.7152
                        34.7915 9.49857 38.5138 9.49857 43.8608V43.8611Z"
                    />
                    <path
                        d="M35.503 5.8374C35.4125 5.85046 35.3498 5.84618 35.2997 5.86933C35.1559 5.93555 35.0123 6.00475
                        34.8774 6.08688C33.0311 7.21191 31.4345 8.61859 30.088 10.3058C27.386 13.692 26.0695 17.5836
                        25.7281 21.8632C25.5464 24.1433 25.213 26.4113 24.9561 28.6856C24.8262 29.8353 24.7147 30.9869
                        24.5937 32.1472C25.7281 31.9613 26.6087 32.3748 27.3635 33.0582C28.3968 33.9938 28.568 35.5089
                        27.6841 36.5873C27.2797 37.0805 26.6673 37.4316 26.0944 37.763C26.0729 37.7753 26.0519 37.7871
                        26.0312 37.7982C26.3494 38.6871 26.6085 39.6458 26.7855 40.6759C26.8527 40.6515 26.9247 40.6254
                        26.966 40.611C29.3642 39.7739 30.8414 38.1492 31.2032 35.6143C31.5112 33.4558 30.3168 31.1677
                        28.2851 30.0086C27.9384 29.8107 27.8284 29.6253 27.8775 29.2281C28.1894 26.7015 28.5205 24.1757
                        28.7508 21.641C28.9642 19.2935 29.6234 17.123 30.6538 14.9887C31.1344 13.9931 32.4997 12.0089
                        32.4997 12.0089C32.4997 12.0089 32.4997 34.214 32.4997 45.2578H20.4463V47.9341H22.466C21.8604
                        50.8181 20.393 52.7477 19.2535 53.8665V44.7673C19.4238 44.0561 19.9625 43.1046 21.5695
                        42.6262C21.6505 42.6021 21.7312 42.5742 21.8122 42.5462C21.7598 42.1201 21.7023 41.7707 21.6903
                        41.5827C21.6124 40.9568 21.4978 40.3728 21.3579 39.8266C21.0784 39.9148 20.8466 39.9906
                        20.6812 40.0502C19.6687 40.4143 18.094 41.731 17.7567 42.0024C17.4194 41.731 15.8447 40.4143
                        14.8322 40.0502C14.6668 39.9906 14.4349 39.9148 14.1556 39.8266C14.0158 40.3728 13.901 40.9567
                        13.8231 41.5827C13.811 41.7707 13.7536 42.1201 13.7012 42.5462C13.7821 42.5742 13.863 42.6021
                        13.944 42.6262C15.5207 43.0957 16.0689 44.0207 16.25 44.7272V53.832C15.1165 52.6957 13.6568
                        50.7593 13.0446 47.934H15.077V45.2577H3.00338V12.0088C3.00338 12.0088 4.36855 13.9931 4.84925
                        14.9886C5.8796 17.1227 6.53887 19.2934 6.75226 21.6409C6.98271 24.1755 7.3136 26.7014 7.62552
                        29.228C7.67466 29.6253 7.56468 29.8106 7.21793 30.0085C5.18622 31.1677 3.99186 33.4558 4.29984
                        35.6142C4.66162 38.1489 6.1388 39.7738 8.53706 40.6109C8.57643 40.6246 8.64382 40.649 8.70847
                        40.6726C8.88071 39.6357 9.13359 38.6733 9.44515 37.7833C9.43298 37.7765 9.42105 37.77 9.40865
                        37.7629C8.83574 37.4315 8.22323 37.0804 7.81899 36.5871C6.935 35.5088 7.10617 33.9936 8.1395
                        33.0581C8.89431 32.3747 9.77496 31.9612 10.9093 32.1471C10.7884 30.9868 10.6768 29.8352 10.5469
                        28.6855C10.2901 26.4112 9.95674 24.1432 9.77496 21.8631C9.4337 17.5835 8.11719 13.6919 5.415
                        10.3057C4.06856 8.61847 2.47198 7.21191 0.625628 6.08676C0.490722 6.00463 0.347108 5.93543
                        0.203374 5.86921C0.153276 5.84618 0.0905343 5.85045 0 5.83728V47.9343H9.81421C11.3231 55.0147
                        17.7515 58.2044 17.7515 58.2044C17.7515 58.2044 24.2127 55.155 25.7005
                        47.9343H35.5034V5.83728L35.503 5.8374Z"
                    />
                </SvgIcon>
                <Typography
                    variant="h6"
                    component="div"
                    sx={{
                        flexGrow: 1,
                        textTransform: 'uppercase',
                        lineHeight: 1
                    }}
                >
                    {t('main_header_title')}
                </Typography>
                <Menu/>
            </Toolbar>
        </AppBar>
    );
}
export default MainBar;
