import i18next from "i18next";
import BrowserLanguageDetector from "i18next-browser-languagedetector";
import {initReactI18next} from "react-i18next";
import I18NextHttpBackend from "i18next-http-backend";

i18next
    .use(I18NextHttpBackend)
    .use(initReactI18next)
    .use(BrowserLanguageDetector)
    .init({
        debug: true,
        fallbackLng: {
            ru: ['uk'],
            '*': ['en']
        },
        interpolation: {
            escapeValue: false
        }
    })
export default i18next