import React, {useRef, useState} from "react"
import {Button, Grid, TextField, Typography} from "@mui/material";
import {emailValid, FEEDBACK, getErrorMessage} from "../../util/util";
import toast from "react-hot-toast";
import {useLoader} from "../../context/Loader";
import {useGoogleReCaptcha} from "react-google-recaptcha-v3";
import axios from "axios";
import {isEmpty} from "lodash";
import {useTranslation} from "react-i18next";

const FeedbackForm = () => {
    const [email, setEmail] = useState<string>('')
    const [contact, setContact] = useState<string>('')
    const [message, setMessage] = useState<string>('')

    const {t} = useTranslation()

    const {setLoading} = useLoader()
    const {executeRecaptcha} = useGoogleReCaptcha()
    const formRef = useRef<HTMLFormElement>(null)

    const send = () => {
        if (!emailValid(email)) {
            toast.error(t('wrong_email'));
            return;
        }
        if (message.length < 3) {
            toast.error(t('empty_message'));
            return;
        }
        if (!executeRecaptcha) {
            toast.error(t('captcha_error'))
            return;
        }
        executeRecaptcha('feedback').then(key => {
            setLoading(true)
            const data = {
                email,
                text: message,
                captcha: key
            }
            if (!isEmpty(contact)) {
                //laravel empty string verification issue
                //@ts-ignore
                data.contact = contact
            }
            axios.post(FEEDBACK, data).then(() => {
                toast.success(t('sent'))
                setEmail('')
                setContact('')
                setMessage('')
            }).catch(e => toast.error(getErrorMessage(e))).finally(() => setLoading(false));
        }).catch(() => {
            toast.error(t('captcha_error'))
        })
    }

    return <form ref={formRef} onSubmit={e => {
        e.preventDefault()
        send()
    }}>
        <Typography style={{

            marginBottom: '10px'
        }}>
            {t('feedback_text')}
        </Typography>
        <Grid container={true} spacing={1} direction='column' justifyContent='center'>
            <Grid item={true}>
                <TextField
                    fullWidth
                    variant='standard'
                    value={email}
                    required={true}
                    onChange={e => setEmail(e.target.value)}
                    type='email'
                    label={t('email')}
                />
            </Grid>
            <Grid item={true}>
                <TextField
                    fullWidth
                    variant='standard'
                    value={contact}
                    onChange={e => setContact(e.target.value)}
                    type='text'
                    label={t('contact')}
                />
            </Grid>
            <Grid item={true}>
                <TextField
                    fullWidth
                    variant='standard'
                    value={message}
                    required={true}
                    onChange={e => setMessage(e.target.value)}
                    type='text'
                    multiline={true}
                    rows={3}
                    label={t('feedback_message')}
                />
            </Grid>
            <Grid item={true}>
                <Button
                    type='submit'
                    sx={{
                        float: 'right'
                    }}
                >
                    {t('send')}
                </Button>
            </Grid>
        </Grid>
    </form>
}
export default FeedbackForm
