import {GeoJSON, useMapEvents} from "react-leaflet";
import React, {useMemo, useState} from "react";
import {blockSideX, blockSideY, LatLng, nearestMultiple, squareGrid} from "../../util/gridUtil";
import Decimal from "decimal.js";
import {FeatureCollection} from "@turf/turf";

const gridStyle = {
    weight: 0.5
};
//Все клетки
const All = () => {
    const map = useMapEvents({
        // @ts-ignore
        'zoom': e => {
            if (e.target.getZoom() < 15) {
                setCenter(null)
            }
        },
        // @ts-ignore
        'moveend': e => {
            if (e.target.getZoom() < 15) {
                return
            }
            const newCenter = e.target.getCenter()
            if (center === null) {
                setCenter(newCenter)
                return
            }
            if (Math.abs(newCenter.lat - center.lat) > blockSideY.toNumber() * 5 || Math.abs(newCenter.lng - center.lng) > blockSideX.toNumber() * 10) {
                setCenter(newCenter)
            }
        }
    });
    const [center, setCenter] = useState<LatLng | null>(map.getCenter());

    const grid = useMemo(() => {
        if (center === null) {
            return null
        }
        const startLat = nearestMultiple(new Decimal(center.lat), blockSideY).toNumber()
        let startLng = nearestMultiple(new Decimal(center.lng), blockSideX).toNumber()
        const addHeight = blockSideY.toNumber() * 20
        const addWidth = blockSideX.toNumber() * 34
        const bbox = [
            startLng - addWidth, startLat - addHeight,
            startLng + addWidth, startLat + addHeight
        ];
        return squareGrid(bbox.map(it => new Decimal(it))) as FeatureCollection
    }, [center])

    if (grid === null) {
        return null
    }

    // @ts-ignore
    return <GeoJSON key={`all-${center}`} data={grid} style={gridStyle}/>
}
export default All
